import React from 'react';
import NoItemsIcon from '../../assets/icons_main/no_items.svg';

const EmptyView = ({
  title='No items uploaded',
  description='Click "Upload new model" button to get started in uploading your own new model seamlessly.',
  label='Upload new model',
  onClick,
}) => {
  return (
    <div className="text-center">
      <div className="inline-block mb-4">
        <img src={NoItemsIcon} alt="No items" className="w-100 h-100" />
      </div>
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        {title}
      </h2>
      <p className="text-sm text-gray-600 mb-6 max-w-xs mx-auto">
        {description}
      </p>
      {onClick && <button id="empty-view-create-new" className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors" onClick={()=>onClick('new')}>
        {label}
      </button>}
    </div>
  );
};

export default EmptyView;