import React, { useState, useRef, useEffect } from 'react';

const RunCard = ({ job, onClick }) => {
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`
  const [results, setResults] = useState(null);
  const [clickable, setClickable] = useState(false);

  useEffect(() => {
    let results = null;
    let clickable = false;

    if(job) {
      const config = job.config;

      if(config?.output) { // for finished jobs (which will have an output)
        // if this is a SocialMediaPipeline, it's a special case for now
        if(job.type === 'SocialMediaPipeline') {
          const result = job.config.output.result
          
          if(result) {
            // if there's a content object, find the first youtube id we can find
            let found = false
            for(const key in result) {
              switch(key) {
                case 'youtube': // youtube specific
                  const youtube = result[key];
                  if(youtube && youtube?.length > 0) {
                    const content = youtube[0]?.content;
                    if(content) {
                      // loop through content array
                      console.log('content', content);
                      for(const item of content) {
                        if(item?.ids) {
                          results = item.ids.map(record => ({
                            title: item.title,
                            link: `https://www.youtube.com/watch?v=${record.id}`
                          }))
                          found = true
                          break;
                        }
                      }
                    }
                  }
                  break;

                default:
                  break;
              }
            }

            if(!found) {
              // if there's no content, deal with it
              if(job.config?.type === 'tiktok') {
                results = [{ title: 'Posted to Tiktok account', link: null }];
              } else {
                results = [{ title: 'No content found', link: null }];
              }
            }
          }
        } else {
          // const results = job.config.output.result?.map(result => 
          //   ({
          //     title: result.title,
          //     link: S3_PATH_PREFIX+'/'+result.media.s3
          //   })
          // );
          // setResults(results);
          results = [{ title: null, link: null }];
        }
      } else {
        // if its a draft social media pipeline, show the message
        if(job.type === 'SocialMediaPipeline') {
          console.log(job)
          if(job.status === 'DRAFT') {
            results = [{ title: `Click to post to ${job.config?.type}`, link: null }];
            clickable = true;

          // or if it failed and needs to be retried manually
          } else if(job.status === 'FAILED') {
            results = [{ title: `Failed, click to retry posting to ${job.config?.type}`, link: null }];
            clickable = true;
          }
        }
      }
    }

    setResults(results);
    setClickable(clickable);
  }, [job, setClickable]);

  const onClickLink = (e) => {
    e.stopPropagation();
  }

  const formatDate = (date, day=true, time=true, at=true) => {
    let formattedDate = new Date(date || 0).toLocaleString('en-US', { 
      month: day ? '2-digit' : undefined, 
      day: day ? '2-digit' : undefined, 
      hour: time ? 'numeric' : undefined, 
      minute: time ? 'numeric' : undefined, 
      hour12: time ? true : undefined
    })
    
    if(day && at) formattedDate = formattedDate.replace(',', '@');
    if(!time && at) formattedDate = formattedDate + ' @';
    return formattedDate;
  }

  return (
    <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 cursor-pointer'} onClick={clickable ? onClick : ()=>{}}>
      <div className={'px-3 flex flex-col w-[40%] space-around'}>
        <div className="flex flex-col sm:flex-row items-start sm:items-center">
          <span className="text-black text-xs font-semibold mb-0">{job?.type}:&nbsp;</span>
          <span className="text-black text-xs font-regular mb-0">({job?.status})</span>
        </div>
        <span className="h-full text-gray text-xs mb-0">{job?.rendermessage}</span>
      </div>
      <span className={'w-[25%] hidden sm:flex'}>{formatDate(job?.renderstart)} - {formatDate(job?.renderend, false)}</span>
      <div className={'w-[25%] flex flex-col sm:hidden'}>
        <span>{formatDate(job?.renderstart, true, false, true)}</span>
        <span>{formatDate(job?.renderstart, false, true, false)}</span>
      </div>
      <span className={'w-[35%]'}>
        {
          results?.map((item, index) => {
            if(item?.link) {
              return (
                <a key={index} href={item.link} target="_blank" rel="noreferrer" className="text-blue-500 underline" onClick={onClickLink}>
                  {item.title}
                </a>
              )
            }
            return item.title;
          })
        }
        {!results && ''}
      </span>
    </div>
  );
}

export default RunCard;