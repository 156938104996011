import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import RunCard from './RunCard';

import { Search, Plus } from 'lucide-react';
import NoItemsIcon from '../../assets/icons_main/no_items.svg';

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const RunsViewMain = ({jobs, title, description, onSearch, onClickJob}) => {
  return (
    <div className="w-100 min-h-0 flex flex-col h-100 overflow-visible sm:overflow-hidden">
      <div className="container mx-auto px-0 py-0">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-4 sm:space-x-20 space-y-2 sm:space-y-auto">
          <div>
            <div className="flex items-center space-x-3 justify-between md:justify-start">
              <h1 className="text-lg font-regular m-0 mr-2">
                {title}
              </h1>
            </div>
            <p className="text-sm text-text-gray m-0">{description}</p>
          </div>
          <div className="flex items-center space-x-4 flex-1">
            <div className="relative flex flex-1">
              <input
                type="text"
                placeholder="Search here..."
                className="pl-10 pr-4 py-2 border rounded-[6px] w-64 bg-[#F9FAFB] flex-1"
                onKeyUp={onSearch}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
          </div>
        </div>
        <div className="mb-14" />
      </div>

      <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 px-3 mb-1 bg-other-gray'}>
        <div className={'flex flex-col w-[50%] space-around'}>
          <h2 className="text-black text-xs font-regular mb-0">Pipeline</h2>
        </div>
        <span className={'w-[25%]'}>Run Time</span>
        <span className={'w-[25%]'}>Content URL</span>
      </div>


      <div className="flex-grow overflow-auto">
        <div className="container mx-auto px-0">
          <>
            {jobs?.map((job, index) => (
              <RunCard key={index} job={job} onClick={() => onClickJob(job)} />
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

const RunsView = ({
    jobs,
    title,
    description,
    onSearch,
    onClickJob,
    }) => {
  return (
    <div className="min-h-0 flex bg-white m-0 flex-col items-center justify-center h-full">
      <RunsViewMain jobs={jobs} title={title} description={description} onSearch={onSearch} onClickJob={onClickJob} />
    </div>
  );
}

export default RunsView;