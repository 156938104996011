import React, {useState} from 'react';
import Fields from '../common/Fields';

import NewView from "../common/NewView";

const EditAccountViewMain = ({account, onClickSave, onClickDelete}) => {
  const [activeAccount, setActiveAccount] = useState({
    id: account.id,
    name: account.name,
    description: account.description,
    publish: account.publish,
  }
  );

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Account
            </h1>
          </div>
        </div>
      </div>

      <div className="h-full overflow-scroll">
        <Fields.TextField label="Name" placeholder="Account Name" value={activeAccount.name} onChange={(e) => {setActiveAccount({...activeAccount, name: e.target.value})}} />
        <Fields.DescriptionField label="Description" placeholder="Description" value={activeAccount.description} onChange={(e) => {setActiveAccount({...activeAccount, description: e.target.value})}} />
        <Fields.EnumField2 
          label="Auto-Publish" 
          info="Should videos be auto-published?" 
          value={activeAccount?.publish} 
          setValue={(value) => setActiveAccount({...activeAccount, publish: value})}
          values={[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' }
          ]} 
        />
        <div className="flex-row space-x-4 mb-4 hidden sm:flex">
          <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeAccount)}} />
          <Fields.Button type='danger' label='Delete' onClick={()=>{onClickDelete(activeAccount)}} />
        </div>
        <div className="flex-row space-x-4 mb-4 flex sm:hidden">
          <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeAccount)}} />
          <Fields.Button type='wide-danger' label='Delete' onClick={()=>{onClickDelete(activeAccount)}} />
        </div>
      </div>
    </NewView>
  );
};

const EditAccountView = ({
    account,
    models,
    voices,
    onClickSave,
    onClickDelete
    }) => {
  return (
    <EditAccountViewMain account={account} models={models} voices={voices} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditAccountView;