import React, { useState, useRef, useEffect } from 'react';

import CardView from '../common/CardView';
import NewVoiceView from "./NewVoiceView";
import PreviewVoiceView from "./PreviewVoiceView";
import AlertView from "../common/AlertView";
import EditVoiceView from "./EditVoiceView";
import VoiceCard from './VoiceCard';

import { Search, Plus, Upload, CheckCircle } from 'lucide-react';
import NoItemsIcon from '../../assets/icons_main/no_items.svg';
import { Play, Pause, Volume2, MoreVertical } from 'lucide-react';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Voices = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_VOICES = `${globalConfig.api}/voices`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const [view, setView] = useState('main');
  const [voices, setVoices] = useState(null);
  const [activeVoice, setActiveVoice] = useState(null);
  const [voiceCards, setVoiceCards] = useState(null);
  const [publicVoiceCards, setPublicVoiceCards] = useState(null);
  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [busy, setBusy] = useState(false);

  // set limit based on account type and voices
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.voices;

      if(curLimit < 0 || voices?.filter(m=>m.owner).length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your voice limit ({voices?.filter(m=>m.owner).length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} voices as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more voices.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${voices?.filter(m=>m.owner).length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [voices]);

  useEffect(() => {
    loadVoices();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      setView('main');
      setBackEvent(false);
    }
  }, [backEvent, setBackEvent]);

  // load available voices for the current user
  const loadVoices = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    axios.get(API_ENDPOINT_VOICES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resVoices = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            active: m.active,
            file: m.file ? `${S3_PATH_PREFIX}/${m.file}` : null,
            sample: m.sample ? `${S3_PATH_PREFIX}/${m.sample}` : null,
            owner: m.owner === user_id
          }
        })

        console.log(resVoices);
        
        // set voices
        setVoices(resVoices);

        // set voice cards for public/private
        setVoiceCards(resVoices.filter(m => m.owner).map(m => <VoiceCard key={m.id} voice={m} onClick={() => onCardClick(m)} />));
        setPublicVoiceCards(resVoices.filter(m => !m.owner).map(m => <VoiceCard key={m.id} voice={m} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (voice) => {
    setActiveVoice(voice);
    setView('edit');
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickPreview = (voice) => {
    console.log('Previewing voice...', voice);
    setActiveVoice(voice);
    setView('preview');
  }

  const onClickUpload = (voice) => {
    console.log(`Uploading voice...${voice}`);

    handleUpload(voice).then(() => {
      setMessage('You have successfully uploaded a voice');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickEdit = (voice) => {
    console.log(`Saving voice...${voice}`);

    handleUpdate(voice).then(() => {
      setMessage('You have successfully updated the voice');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickDelete = (voice) => {
    console.log(`Deleting voice...${voice}`);

    handleDelete(voice).then(() => {
      setMessage('You have successfully deleted the voice');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  // create a new voice
  const handleUpload = (voice) => {
    // Reset progress
    setUploadProgress(0);

    // Create form data
    const formData = new FormData();
    formData.append('file', voice.file);
    formData.append('name', voice.name);
    formData.append('description', voice.description);
    formData.append('type', 'elevenlabs');

    // set busy state
    setBusy(true);

    // post to endpoint
    return axios.post(API_ENDPOINT_VOICES, formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 80) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    })
    .then(response => {
      setUploadProgress(100);

      // reload voices
      loadVoices();

      // reset busy state
      setBusy(false);
    })
    .catch(error => {
      console.error(error);

      // reset busy state
      setBusy(false);

      throw error;
    });
  };

  // update a voice
  const handleUpdate = (voice) => {
    return axios.patch(
      `${API_ENDPOINT_VOICES}/${voice.id}`, 
      {
        name: voice.name,
        description: voice.description,
        public: voice.public
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload voices
        loadVoices();
      })
  }

  // delete a voice
  const handleDelete = (voice) => {
    return axios.delete(`${API_ENDPOINT_VOICES}/${voice.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload voices
      loadVoices();
    })
  }

  return (
    (view==='main' && <CardView
      view={view}
      cards={voiceCards} 
      publicCards={publicVoiceCards} 
      toggle={true} 
      title="Voices"
      description="Find all the voices you have created here"
      info={tag}
      label="Create Voice"
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No voices created'
      emptyDescription='Click "Create new voice" button to get started generating content automatically.'
      emptyLabel='Create new voice'
      emptyEnabled={false}
    />) ||
    (view==='new' && <NewVoiceView
      onClickPreview={onClickPreview}
    />) ||
    (view==='preview' && <PreviewVoiceView 
      name={'Storia'}
      description={'A voice created using vroidstudio'}
      voice={activeVoice}
      uploadProgress={uploadProgress}
      onUpload={onClickUpload}
      onBack={()=>setView('new')}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) || 
    (view==='edit' && <EditVoiceView 
      voice={activeVoice}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
    />)
  );
}

export default Voices;