import AuthService from '../services/auth.service';
import UserService from '../services/user.service';

const getAuth = (redirect = true) => {
  let tokens = localStorage.getItem('tokens')
  let user = localStorage.getItem('user')

  try {
    tokens = JSON.parse(tokens);
    user = JSON.parse(user);
  } catch (error) {
    console.error(error);
    tokens = null
    user = null
  }

  if(!tokens || !user) {
    localStorage.removeItem('tokens');
    localStorage.removeItem('user');

    if(redirect) {
      window.location.href = '/login';
    }
    return null
  }

  return {tokens, user};
}

function authHeader(redirect = true) {
  const auth = getAuth(redirect);

  if (auth && auth.tokens && auth.tokens.access.token) {
    return { Authorization: 'Bearer ' + auth.tokens.access.token };
  } else {
    return {};
  }
}

// check refresh token and refresh if needed
const authRefresh = (redirect = true) => {
  const auth = getAuth(false); // if user is not authenticated, do nothing otherwise we'll get a redirect loop

  //console.log(auth)

  if (auth && auth.tokens) {
    // check if the access token is expired
    if(new Date(auth.tokens.access.expires) < new Date(Date.now())) {
      // refresh and get the new tokens
      AuthService.refresh(auth.tokens.refresh.token)
        .then(data => {
          if(data) {
            localStorage.setItem("tokens", JSON.stringify(data));
          } else {
            localStorage.removeItem("tokens");
            localStorage.removeItem("user");

            if(redirect) {
              window.location.href = '/login';
            }
            return;
          }
        })
    }
  } else {
    // clear dangling tokens
    localStorage.removeItem("tokens");
    localStorage.removeItem("user");
  }
};

// update user
const updateUser = (redirect = true) => {
  const auth = getAuth(false); // if user is not authenticated, do nothing otherwise we'll get a redirect loop

  if (auth && auth.user && auth.tokens) {
    // get the user
    return UserService.getUser(auth.user.id, auth.tokens.access.token)
      .then(data => {
        if(data) {
          localStorage.setItem("user", JSON.stringify(data));
        }

        return data
      })
  }
};

export { getAuth, authHeader, authRefresh, updateUser };
export default authHeader;