import React, { useEffect } from 'react';

const Feedback = ({
    backEvent,
    setBackEvent,
    setBackEnabled
}) => {

    // enable back button
    useEffect(() => {
        setBackEnabled(true);
    }, [setBackEnabled]);

    // handle back event
    useEffect(() => {
        if (backEvent) {
            window.history.back();
            setBackEvent(false);
        }
    }, [backEvent, setBackEvent]);

    const FEEDBACK_SRC = `
        <div data-canny ></div>
        <script>!function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script");</script>
        <script>
        Canny('render', {
            boardToken: '13de73a2-99d1-9a94-9bde-c5dcb704bbd6',
            basePath: null, // See step 2
            ssoToken: null, // See step 3,
            theme: 'light', // options: light [default], dark, auto
        });
        </script>
    `;
    return (
        <iframe srcDoc={FEEDBACK_SRC} title="Feedback" className="w-full h-full" />
    );
};

export default Feedback;