import React, {useState} from 'react';
import Fields from '../common/Fields';

import NewView from "../common/NewView";

const EditVoiceViewMain = ({voice, onClickSave, onClickDelete}) => {
  const [activeVoice, setActiveVoice] = useState(voice);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Voice
            </h1>
          </div>
        </div>
      </div>

      <Fields.TextField label="Name" placeholder="Voice Name" value={activeVoice.name} onChange={(e) => {setActiveVoice({...activeVoice, name: e.target.value})}} />
      <Fields.DescriptionField label="Description" placeholder="Description" value={activeVoice.description} onChange={(e) => {setActiveVoice({...activeVoice, description: e.target.value})}} />
      <div className="flex flex-row space-x-4 mb-4">
        <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeVoice)}} />
        <Fields.Button type='danger' label='Delete' onClick={()=>{onClickDelete(activeVoice)}} />
      </div>
    </NewView>
  );
};

const EditVoiceView = ({
    voice,
    onClickSave,
    onClickDelete
    }) => {
  return (
    <EditVoiceViewMain voice={voice} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditVoiceView;