import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const RunDetailViewMain = ({job, onClickPost}) => {
  const API_ENDPOINT_JOBS = `${globalConfig.api}/renderjobs`
  const API_ENDPOINT_ACCOUNTS = `${globalConfig.api}connectedaccounts`
  const API_ENDPOINT_TIKTOK = `${globalConfig.api}tiktok`

  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const PRIVACY_OPTIONS = {
    'PUBLIC_TO_EVERYONE': 'Public',
    'MUTUAL_FOLLOW_FRIENDS': 'Friends',
    'SELF_ONLY': 'Only Me',
    'FOLLOWER_OF_CREATOR': 'Followers',
  }

  const [info, setInfo] = useState({});
  const [message, setMessage] = useState('');
  const [renderjob, setRenderjob] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const [media, setMedia] = useState(null);

  const [privacyOptions, setPrivacyOptions] = useState([])
  const [allowedOptions, setAllowedOptions] = useState([])

  // get media from renderjob
  useEffect(() => {
    const media_s3 = renderjob?.config?.output?.result?.map((r) => {{
      return r?.media?.s3
    }}).filter((m) => m);

    const title = renderjob?.config?.output?.result?.map((r) => {{
      return r?.title
    }}).filter((m) => m)[0];

    const description = renderjob?.config?.output?.result?.map((r) => {{
      return r?.description
    }}).filter((m) => m)[0];

    if(media_s3 && media_s3.length > 0) {
      const fullUrl = `${S3_PATH_PREFIX}/${media_s3[0]}`;
      console.log('media', fullUrl);
      setMedia(fullUrl);
    }

    setInfo({
      ...info,
      caption: title,
    })
  }, [renderjob]);

  // get renderjob for a job
  useEffect(() => {
    const renderjob_id = job?.config?.renderjob;

    if(renderjob_id) {
      axios.get(`${API_ENDPOINT_JOBS}/${renderjob_id}`, {
        headers: {
          ...authHeader()
        }
      }).then((response) => {
        console.log('renderjob', response.data);
        setRenderjob(response.data);
      });
    }
  }, [job]);
  
  // get accounts for a job
  useEffect(() => {
    const account_ids = job?.config?.accounts;

    // for each account_id, get the account
    if(account_ids) {
      const promises = account_ids.map((account_id) => {
        return axios.get(`${API_ENDPOINT_ACCOUNTS}/${account_id}`, {
          headers: {
            ...authHeader()
          }
        }).catch((error) => {
          console.log('error', error);
        });
      });

      Promise.all(promises)
      .then((responses) => {
        const accounts = responses.map((response) => {
          return response?.data;
        });

        // only keep defined accounts that are tiktok
        setAccounts(accounts.filter((a) => a).filter((a) => a.type === 'tiktok'));
      });
    }
  }, [job]);

  // get permissions for a tiktok account
  useEffect(() => {
    if(accounts && accounts.length === 1) { // exactly 1 tiktok account ONLY
      const account_id = accounts[0].id;

      axios.get(`${API_ENDPOINT_TIKTOK}/${account_id}`, {
        headers: {
          ...authHeader()
        }
      }).then((response) => {
        console.log('permissions', response.data.data);
        setPermissions(response.data.data);
      });
    }
  }, [accounts]);

  // update info
  useEffect(() => {
    if(permissions) {
      // privacy
      const privacy = permissions?.privacy_level_options;
      if(privacy) {
        let newPrivacyOptions = privacy.map((p) => {
          return {
            value: p,
            label: PRIVACY_OPTIONS[p]
          };
        });

        newPrivacyOptions.push({
          value: 'select_one',
          label: 'Select One'
        }); // add a blank option

        console.log('privacy options', newPrivacyOptions);
        setPrivacyOptions(newPrivacyOptions);
      }

      // allowed
      const allowed = []
      if(!permissions?.comment_disabled) {
        allowed.push('Comment');
      }
      if(!permissions?.duet_disabled) {
        allowed.push('Duet');
      }
      if(!permissions?.stitch_disabled) {
        allowed.push('Stitch');
      }

      const newAllowedOptions = allowed.map((a) => {
        return {
          value: a,
          label: a
        };
      });

      console.log('allowed options', newAllowedOptions);
      setAllowedOptions(newAllowedOptions);
    }
  }, [permissions]);

  const updateInfo = (field, value) => {
    setInfo({...info, [field]: value});
  }

  useEffect(() => {
    if(info) {
      if(info.your_brand && info.other_brand) {
        setMessage(
          <div className="text-xs">By posting, you agree to TikTok's <a className="text-link" href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></div>
        );
      } else if(info.your_brand) {
        setMessage(
          <div className="text-xs">By posting, you agree to TikTok's <a className="text-link" href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></div>
        );
      } else if(info.other_brand) {
        setMessage(
          <div className="text-xs">By posting, you agree to TikTok's <a className="text-link" href="https://www.tiktok.com/legal/page/global/bc-policy/en">Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></div>
        );
      } else {
        setMessage('');
      }
    }
  }
  , [info]);

  return (
    <NewView>
      <div className="flex flex-col sm:flex-row h-full space-y-4 sm:space-y-auto sm:space-x-10 px-2 py-2 justify-around">
        <div className="w-auto sm:flex-1 h-1/2 sm:h-auto overflow-scroll">
          <Fields.Video label="Your&nbsp;video&nbsp;is&nbsp;ready!" info="Get&nbsp;ready&nbsp;to&nbsp;post" url={media} />
        </div>
        <div className="flex flex-col sm:flex-2 sm:w-3/5 h-full overflow-scroll">
          <Fields.TextField label="User account" value={accounts?.map(a=>a?.name).filter(a=>a)} disabled={true} onChange={()=>{}} />
          <Fields.DescriptionField label="Caption" placeholder="Add a title that describes your video" value={info?.caption} onChange={(e) => updateInfo('caption', e.target.value)} />
          <Fields.EnumDropdown label="Who can view this video" value={info?.visibility || 'select_one'} setValue={(value) => updateInfo('visibility', value)} values={privacyOptions} />
          <Fields.MultiEnumField2 label="Allow users to" value={info?.permissions} setValue={(values) => updateInfo('permissions', values)} values={allowedOptions} />
          
          <Fields.BoolField2 label="Disclose video content" value={info?.disclose_content} setValue={(value) => updateInfo('disclose_content', value)} />
          <div className="text-xs">Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promose yourself, a third party, or both.</div>
          
          {info?.disclose_content &&
          <div>
            <br />

            <div className="text-xs text-dark-purple bg-light-purple rounded p-2">Your video will be labeled "Promotional content". This cannot be changed once your video is posted.</div>
        
            <br />
            
            <Fields.BoolField2 label="Your brand" value={info?.your_brand} setValue={(value) => updateInfo('your_brand', value)} />
            <div className="text-xs">You are promoting yourself or your own business. This video will classified as Brand Oragnic.</div>
            
            <br />
            
            <Fields.BoolField2 label="Branded content" value={info?.other_brand} setValue={(value) => updateInfo('other_brand', value)} />
            <div className="text-xs">You are promoting another brand or third party. This video will be classified as Branded Content.</div>
            
            <br />
            
            {message}
          </div>
          }
          <br />
          <Fields.Button type='wide-styled' label='Post' onClick={()=>onClickPost(job, info)} />
        </div>
      </div>
    </NewView>
  );
};

const RunDetailView = ({
    job,
    onClickPost
    }) => {
  return (
    <RunDetailViewMain job={job} onClickPost={onClickPost} />
  );
}

export default RunDetailView;