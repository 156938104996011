import { useEffect, useState } from 'react';
import { ArrowLeft, Bell, User, Coins, Menu} from 'lucide-react';

import { updateUser } from '../../utils/auth';

import { getAuth } from '../../utils/auth';

const Header = ({backEnabled, setBackEvent, toggleMenuEvent}) => {
  const [credits, setCredits] = useState(0);
  const [limit, setLimit] = useState(false);

  // figure out the user role
  useEffect(() => {
    const auth = getAuth();
    const user = auth?.user;
    const role = user?.role;

    if(user && role === 'new') {
      setLimit(true);
    } else {
      setLimit(false);
    }
  }, []);

  // get user credits
  useEffect(() => {
    updateUser().then((user) => {
      if(user) {
        setCredits(user.credits || 0);
      }
    });
  }, []);

  // toggle menu
  const toggleMenu = () => {
    toggleMenuEvent();
  }

  return (
    <header className="h-[40px] mt-[10px] sm:!mt-0 bg-white sm:!mr-[29px] py-4 flex justify-end items-center border-b border-[#E4E7EC]">
      {
        <button className={`flex flex-row mr-4 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200 sm:hidden ${backEnabled? 'hidden' : ''}`}
          onClick={toggleMenu}
        >
          <Menu size={27} id="menu" className="ml-2" />
        </button>
      }
      {backEnabled && 
        <button 
          className="flex flex-row mr-4 hover:bg-gray-100 p-2 rounded-md transition-colors duration-200 justify-center items-center"
          onClick={() => {
            setBackEvent(true);
          }}
        >
        <ArrowLeft size={20} className="rounded-full mr-2 bg-other-gray p-2 w-full h-full" color="black" />
          Back
        </button>
      }
      {limit && 
        <div className="flex flex-row flex-2 bg-gold mr-4 p-2 w-full rounded-md transition-colors duration-200 justify-center items-center">
          <span className="text-xs">You must validate your email. Re-send email&nbsp;
            <a href="verify" className="text-white font-semibold">
              here
            </a>
          </span>
        </div>
      }
      <div className="flex flex-1 justify-end">
        <div className="h-[30px] mr-1 hidden sm:flex">
          <button className="flex flex-row mr-3 hover:bg-other-gray p-2 rounded-md transition-colors duration-200 space-x-3">
            <Coins size={20} className="text-gold" />
            <span className="ml-1 text-sm">{credits}&nbsp;credits</span>
          </button>
        </div>
        <div className="h-[30px] mr-5 border-l border-[#E4E7EC] hidden sm:flex" />
        <button className="mr-3 hover:bg-other-gray p-2 rounded-md transition-colors duration-200 hidden sm:flex">
          <Bell size={20} />
        </button>
        <button className="mr-3 hover:bg-otehr-gray p-2 rounded-md transition-colors duration-200 hidden sm:flex">
          <User size={20} />
        </button>
      </div>
    </header>
  );
};

export default Header;