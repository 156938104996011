import React, {useState} from 'react';
import { Search, Plus } from 'lucide-react';

import EmptyView from '../common/EmptyView';

const ExistingView = ({cards=[], publicCards=[], toggle=true, title='Title', description='Description', info='Premium 8/8', label='Label', onClick=()=>{}, onSearch=()=>{}, footer='Footer'}) => {
  const [activeTab, setActiveTab] = useState('user');

  return (
    <div className="w-100 min-h-0 flex flex-col h-100 overflow-visible sm:overflow-hidden">
      <div className="container mx-auto px-0 py-0">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-4 sm:space-x-20 space-y-2 sm:space-y-auto">
          <div>
            <div className="flex items-center space-x-3 justify-between md:justify-start">
              <h1 className="text-lg font-regular m-0 mr-2">
                {title}
              </h1>
              <span className="bg-gray-200 text-black bg-light-purple text-[11px] font-regular px-2 py-1 rounded-full">
                {info}
              </span>
              <div className="flex flex-2 sm:hidden">&nbsp;</div>
              <button 
                className="flex sm:flex-none text-sm bg-black text-white px-1 py-1 rounded-[10px] flex sm:hidden items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
                onClick={()=>onClick('new')}
              >
                <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
                {label}
              </button>
            </div>
            <p className="text-sm text-text-gray m-0">{description}</p>
          </div>
          <div className="flex sm:items-center space-x-4 flex-1">
            <div className="relative flex flex-1">
              <input
                type="text"
                placeholder="Search here..."
                className="pl-10 pr-4 py-2 border rounded-[6px] w-64 bg-[#F9FAFB] flex-1"
                onKeyUp={onSearch}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <button 
              className="text-sm bg-black text-white px-3 py-2.5 rounded-[10px] hidden sm:flex items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
              onClick={()=>onClick('new')}
            >
              <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
              {label}
            </button>
          </div>
        </div>

        {toggle && <div className="mb-4 bg-other-gray inline-flex py-[7.5px] px-[10.5px] rounded-[8px] font-semibold text-sm text-black">
          <button
            className={`min-w-40 px-4 py-[6px] rounded-[4px] transition-colors ${
              activeTab === 'user'
                ? 'bg-light-purple'
                : 'hover:text-dark-purple font-normal'
            }`}
            onClick={() => setActiveTab('user')}
          >
            User {title}
          </button>
          <button
            className={`min-w-40 px-4 py-[6px] rounded-[4px] transition-colors ${
              activeTab === 'public'
                ? 'bg-light-purple'
                : 'hover:text-dark-purple font-normal'
            }`}
            onClick={() => setActiveTab('public')}
          >
            Public {title}
          </button>
        </div>}
        {!toggle && <div className="mb-14" />}
      </div>

      <div className="flex-grow overflow-visible sm:overflow-auto">
        <div className="container mx-auto px-0">
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4 mb-8">
            {activeTab === 'user' && cards.map((card, index) => (
              card
            ))}
            {activeTab === 'public' && publicCards.map((card, index) => (
              card
            ))}
          </div>
        </div>

        {footer && <div className="bg-white py-4">
          <div className="mx-[10%] mb-[35px] border-t border-[#E4E7EC]" />
          <div className="container mx-auto px-4">
            <div className="flex flex-col m-0 space-y-1 text-center text-sm text-text-gray">
              {footer}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

const CardView = ({
    cards=[], 
    publicCards=[], 
    toggle=true, 
    title='Title', 
    description='Description', 
    info='Premium 8/8', 
    label='Label', 
    onClick=()=>{}, 
    onSearch=()=>{}, 
    footer='Footer',
    emptyTitle='No models uploaded',
    emptyDescription='Click "Upload new model" button to get started in uploading your own new model seamlessly.',
    emptyLabel='Upload new model',
    emptyEnabled=true,
    id
  }) => {
  return (
    <div className="min-h-0 flex bg-white m-0 flex-col items-center justify-center h-full" id={`card-view-${id}`}>
    {!cards ? 
      <EmptyView 
          title={"Loading..."}
          description={"Please wait a moment..."}
      /> :
    (cards?.length === 0 && emptyEnabled) ? 
      <EmptyView 
          title={emptyTitle}
          description={emptyDescription}
          label={emptyLabel}
          onClick={onClick}
      /> : 
      <ExistingView 
          cards={cards}
          publicCards={publicCards}
          toggle={toggle}
          title={title}
          description={description}
          info={info}
          label={label}
          onClick={onClick}
          onSearch={onSearch}
          footer={footer}
      />
    }
    </div>
  );
}

export default CardView;