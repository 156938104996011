import React, {useState} from 'react';
import Fields from '../common/Fields';
import NewView from "../common/NewView";

const NewModelViewMain = ({onClickPreview}) => {
  const [activeModel, setActiveModel] = useState({});

  const onFileChange = (e) => {
    const file = e.target.files[0];
    console.log('file:', file);

    setActiveModel({...activeModel, file: file});
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              New Model
            </h1>
          </div>
          <p className="text-sm text-text-gray m-0">You can find free models on <a href="http://www.hub.vroid.com" className="text-blue-600 hover:underline">www.hub.vroid.com</a> or you can create your own. Note any model created using vroidstudio will work</p>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <Fields.TextField label="Name" placeholder="Model's Name" value={activeModel.name} onChange={(e) => {setActiveModel({...activeModel, name: e.target.value})}} />
        {/* <Fields.DescriptionField label="Description" placeholder="Description" value={activeModel.description} onChange={(e) => {setActiveModel({...activeModel, description: e.target.value})}} /> */}
        <Fields.FileUploadField 
          label="Choose file" 
          description="Click to upload or drag and drop" 
          info="VRM (max. 20MB)" 
          alert={activeModel?.file ? `File: ${activeModel.file.name}` : "No file chosen yet"} 
          onChange={onFileChange}
        />
        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button type='wide-styled' label='Preview' onClick={()=>onClickPreview(activeModel)} />
        </div>
      </div>
    </NewView>
  );
};

const NewModelView = ({
    onClickPreview
    }) => {
  return (
    <NewModelViewMain onClickPreview={onClickPreview} />
  );
}

export default NewModelView;