import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const EditMemoryViewMain = ({
      node,
      media,
      mediaProgress,
      onClickSaveMemory,
      onClickDeleteMemory,
      onClickAddMedia,
      onClickDeleteMedia,
    }) => {

  const MEDIA_PREFIX = 'https://cdn1.netizens.ai/'
  const MEDIA_TYPES = [
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'audio', label: 'Audio' },
    { value: 'text', label: 'Text' },
  ];

  const [activeMemory, setActiveMemory] = useState(null);
  const [newMedia, setNewMedia] = useState({});
  const [newMediaValid, setNewMediaValid] = useState(false);

  useEffect(() => {
    setActiveMemory(node)
  }, [node]);

  useEffect(() => {
    if(newMedia?.type) {
      if(['image', 'video', 'audio'].includes(newMedia?.type) && newMedia?.files) {
        setNewMediaValid(true);
      } else if(newMedia?.type === 'text' && newMedia?.description) {
        setNewMediaValid(true);
      } else {
        setNewMediaValid(false);
      }
    } else {
      setNewMediaValid(false);
    }
  }, [newMedia]);

  const updateField = (field, value) => {
    if(field === 'startDate') {
      setActiveMemory({...activeMemory, properties: {...activeMemory.properties, date: [value, activeMemory.properties.date[1]]}});
    } else if(field === 'endDate') {
      setActiveMemory({...activeMemory, properties: {...activeMemory.properties, date: [activeMemory.properties.date[0], value]}});
    } else {
      setActiveMemory({...activeMemory, properties: {...activeMemory.properties, [field]: value}});
    }
  }

  const updateMediaField = (field, value) => {
    setNewMedia({...newMedia, [field]: value});
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Memory
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <div className="flex flex-col justify-start pr-10">
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="text-lg font-regular m-0 mr-2">
                Experience
              </h1>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between mt-2 sm:space-x-10 sm:pr-10">
            <div className="w-full flex-1">
                  <Fields.DateField label="Start date" value={activeMemory?.properties?.date[0]} setValue={(value) => updateField('startDate', value)} />
            </div>
            <div className="w-full flex-1">
                  <Fields.DateField label="End date" value={activeMemory?.properties?.date[1]} setValue={(value) => updateField('endDate', value)} />
            </div>
          </div>
          <Fields.DescriptionField label="Description" placeholder="Description" value={activeMemory?.properties?.description} onChange={(e) => updateField('description', e.target.value)} />
          <div className="flex flex-row space-x-4 mb-4">
            <Fields.Button type='wide-styled' label='Update Memory' onClick={()=>onClickSaveMemory(activeMemory)} />
            <Fields.Button type='wide-styled' label='Delete Memory' onClick={()=>onClickDeleteMemory(activeMemory)} />
          </div>
        </div>
        
        <div className="flex flex-col space-y-0">
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="text-lg font-regular m-0 mr-2">
                New Media
              </h1>
            </div>
          </div>
          <div className="flex flex-row justify-between space-x-10 pr-10">
            <div className="w-full flex-1">
              <Fields.SingleSelect label="Type" value={newMedia?.type} options={MEDIA_TYPES} onChange={(value) => updateMediaField('type', value)} />
              <Fields.DescriptionField label="Description" placeholder="Description" value={newMedia?.description} onChange={(e) => updateMediaField('description', e.target.value)} />
              {['image', 'video', 'audio'].includes(newMedia?.type) && <Fields.FilesUploadField label="Upload Media" value={newMedia?.files} onChange={(e) => updateMediaField('files', e.target.files)} />}
              <Fields.Button type='wide-styled' label='Add Media' onClick={()=>onClickAddMedia(newMedia)} disabled={!newMediaValid} disabledLabel={'Fill out all fields'} />
              {mediaProgress !== null && 
                <progress value={mediaProgress} max="100" className="w-full">
                </progress>}
            </div>
          </div>

          <div className="flex flex-col pr-10">
          {media?.map((item, index) => (
            <div key={item.id} className = "flex flex-col sm:flex-row w-full my-4 space-x-2 items-center">
              <div className="h-full min-w-0 flex flex-col flex-1 shrink w=[25%] items-start sm:items-center justify-center">
                <label className="block text-sm font-light mb-1">Type: {item.type}</label>
                {item.type === 'video' && <Fields.Video url={`${MEDIA_PREFIX}${item.s3}`} />}
                {item.type === 'image' && <Fields.Image url={`${MEDIA_PREFIX}${item.s3}`} />}
                {item.type === 'audio' && <Fields.Audio url={`${MEDIA_PREFIX}${item.s3}`} />}
              </div>
              <div className="flex flex-row flex-2 w-full sm:w-[75%] space-x-2 items-start">
                <div key="description" className="w-full">
                  <Fields.DescriptionField label="Text:" value={item.description} onChange={null} disabled={true} />
                </div>
                
                <div className = "flex flex-col mt-4">
                  <p><b>Embedding:</b>&nbsp;{item.embedding === null ? 'False' : 'True'}</p>
                  <p><b>Sections:</b>&nbsp;{(item.sections === null || (item.sections !== null && item.sections.length === 0)) ? 'False' : 'True'}</p>
                  <div className="memories-actions">
                    <Fields.Button type='wide-styled' label='Delete' onClick={()=>onClickDeleteMedia(item)} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </NewView>
  );
};

const EditMemoryView = ({
      node,
      media,
      mediaProgress,
      onClickSaveMemory,
      onClickDeleteMemory,
      onClickAddMedia,
      onClickDeleteMedia,
    }) => {
  return (
    <EditMemoryViewMain
      node={node}
      media={media}
      mediaProgress={mediaProgress}
      onClickSaveMemory={onClickSaveMemory}
      onClickDeleteMemory={onClickDeleteMemory}
      onClickAddMedia={onClickAddMedia}
      onClickDeleteMedia={onClickDeleteMedia}
    />
  );
}

export default EditMemoryView;