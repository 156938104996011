import React, { useState, useEffect } from 'react';

import ListView from '../common/ListView';
import NewAccountView from "./NewAccountView";
import AlertView from "../common/AlertView";
import EditAccountView from "./EditAccountView";
import AccountCard from './AccountCard';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const AccountsReceiver = () => {
  const onAuthInstagram = () => {
    console.log('instagram auth');
    const query = new URLSearchParams(window.location.search);

    // get the code
    const code = query.get('code');

    // get things from local storage
    const name = localStorage.getItem('instagram_account_name');
    const description = localStorage.getItem('instagram_account_description');

    // create the account on the backend
    const body = {
      "instagramCode": code,
      "name": name,
      "description": description,
      "type": "instagram",
      "redirect": "https://netizens.ai/oauth2/instagram"
    }

    axios.post(`${globalConfig.api}connectedaccounts`, 
      body, 
      {
        headers: {
          ...authHeader()
        }
    }).then((response) => {
      // close window
      window.close();
    }).catch((error) => {
      // close window
      window.close();
    });
  }

  const onAuthTiktok = () => {
    console.log('tiktok auth');
    const query = new URLSearchParams(window.location.search);

    // get the code
    const code = query.get('code');

    // get things from local storage
    const name = localStorage.getItem('tiktok_account_name');
    const description = localStorage.getItem('tiktok_account_description');

    // create the account on the backend
    const body = {
      "tiktokCode": code,
      "name": name,
      "description": description,
      "type": "tiktok",
      "redirect": "https://netizens.ai/oauth2/tiktok"
    }

    axios.post(`${globalConfig.api}connectedaccounts`, 
      body, 
      {
        headers: {
          ...authHeader()
        }
    }).then((response) => {
      // close window
      window.close();
    }).catch((error) => {
      // close window
      window.close();
    });
  }

  const auths = {
    'tiktok': onAuthTiktok,
    'instagram': onAuthInstagram,
  }
  
  // figure out the type of auth we are dealing with
  useEffect(() => {
    const url = window.location.href;
    
    // get the final part of the url
    const urlParts = url.split('/');
    let lastPart = urlParts[urlParts.length - 1];

    // strip out any query params
    const lastPartParts = lastPart.split('?');
    lastPart = lastPartParts[0];

    // see if there's a matching auth
    if(auths[lastPart]) {
      auths[lastPart]();
    }
  }, []);

  return (
    <div></div>
  );
}

export default AccountsReceiver;