import React, { useState, useRef, useEffect } from 'react';

const PipelineCard = ({ pipeline, onClick }) => (
  <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 cursor-pointer'} onClick={onClick}>
    <div className={'px-3 flex flex-col w-[50%] space-around'}>
      <h3 className="text-black text-sm font-regular mb-0">{pipeline.name}</h3>
      <p className="h-full text-gray text-xs mb-0">{pipeline.description}</p>
    </div>
    <span className={'w-[25%]'}>{pipeline.credits ? pipeline.credits : 0}</span>
    <span className={'w-[25%]'}>available</span>
  </div>
);

export default PipelineCard;