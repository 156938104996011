import React, { useState, useEffect } from 'react';
import SimpleIcon from "../common/SimpleIcon";
import { siGoogle } from 'simple-icons';
import icon_google from '../../assets/icons_main/google.svg';

import AuthView from './AuthView';
import LoginFields from './LoginFields';

import config from '../../config/config';
import { getAuth } from '../../utils/auth';
import AuthService from '../../services/auth.service';
import OAuth2Login from 'react-simple-oauth2-login';

const LoginMain = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);

  // if user is already logged in, redirect to netizens page
  useEffect(() => {
    const auth = getAuth(false);
    if(auth) {
      window.location.href = 'netizens';
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!email || !password) {
      setMessage('Please fill in all fields');
      return;
    }

    AuthService.login(email, password)
      .then((data) => {
        if(data) {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));

          if(data.user.isEmailVerified) {
            window.location.href = 'models';
          } else {
            window.location.href = 'verify';
          }

        } else {
          setMessage('Error logging in');
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      })
  }

  const oauth2_onSuccessGoogle = (response) => {
    const code = response.code

    setMessage(null);

    if (true) {
      AuthService.loginGoogle(
        code,
        (config.url+'/login').replace(/([^:]\/)\/+/g, "$1")
        ).then(
        (data) => {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));
          
          window.location.href = 'netizens';
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
    } else {
      setMessage('Login disallowed');
    }
  };

  const oauth2_onFailure = response => console.error(response);

  return (
    <div>
      <LoginFields.Header title="Log In" />
      <LoginFields.Description description="Enter your credentials to access your account" />
      {message && <LoginFields.Error error={message} />}
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="space-y-4">
          <LoginFields.Email email={email} setEmail={setEmail} />
          <LoginFields.Password password={password} setPassword={setPassword} />
          <LoginFields.Info label="Forgot Password?" href="reset" />
          <LoginFields.Button label="Log into Account" onClick={handleSubmit} />
        </div>
      </form>

      <div className="mt-6">
        <LoginFields.DashedText text="Or" />

        <div className="mt-6">
          <OAuth2Login
            authorizationUrl={config.google.authorization_url}
            responseType="code"
            clientId={config.google.client_id}
            redirectUri={(config.url+'/login').replace(/([^:]\/)\/+/g, "$1")}
            scope={config.google.scopes.join(' ')}
            onSuccess={oauth2_onSuccessGoogle}
            onFailure={oauth2_onFailure}
            render={({ onClick }) => {return (
                <LoginFields.Button 
                  label="Sign in with Google" 
                  type="google" 
                  icon={<img src={icon_google} alt="google" className="h-5 w-5 mr-2" />} 
                  onClick={onClick}
                />
              )}
            }
          />
        </div>
      </div>

      <div className="mt-6 text-center text-sm">
        <span className="text-text-gray">Are you new here? </span>
        <LoginFields.Link label="Create an account" link="register" />
      </div>
    </div>
  );
};

const Login = () => {
  return (
    <AuthView>
      <LoginMain />
    </AuthView>
  );
};

export default Login;