import React from 'react';

import NewView from "../common/NewView";

const SettingsView = ({children}) => {
  return (
    <NewView>
        <div className="w-2/5">
            {children}
        </div>
    </NewView>
  );
}

export default SettingsView;