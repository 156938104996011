import React, { useState, useEffect } from 'react';
import UserChart from './UserChart';
import ContentChart from './ContentChart';
import CharacterChart from './CharacterChart';
import ScheduleChart from './ScheduleChart';
import AccountChart from './AccountChart';
import PipelineChart from './PipelineChart';

const Admin = () => {
    return (
        <div className="grid grid-cols-2 gap-4 h-dvh overflow-y-scroll">
            <div className="w-full h-full">
                <UserChart />
            </div>
            <div className="w-full h-full">
                <CharacterChart />
            </div>
            <div className="w-full h-full">
                <AccountChart />
            </div>
            <div className="w-full h-full">
                <PipelineChart />
            </div>
            <div className="w-full h-full">
                <ScheduleChart />
            </div>
            <div className="w-full h-full">
                <ContentChart />
            </div>
        </div>
    )
}

export default Admin;