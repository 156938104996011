import React, { useState, useRef, useEffect } from 'react';

import NewView from '../common/NewView';

const Welcome = ({onClick}) => {
  return (
    <NewView>
      <div className="fixed z-[9999999] top-0 left-0 w-dvw h-dvh flex flex-col items-center justify-center bg-dark-gray-transparent">
        <div className="bg-white p-4 rounded-lg text-center w-[70%] h-auto flex flex-col items-center justify-between space-y-2 border rounded-[8px] border-[#E4E7EC]">
          <h2 className="text-xl font-bold mb-2">Walkthrough Video</h2>
          <p className="text-sm text-gray-600 mb-2">You can watch this video again at any time by going to the Settings page</p>
          <iframe 
            width="100%" 
            height="auto" 
            style={{
              aspectRatio: '16/9',
              maxHeight: '70dvh',
            }}
            src="https://www.youtube.com/embed/zQK9N58v_ek?si=sLa2gQgD43j5WYxA" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
          <button
            onClick={onClick}
            className="w-1/2 bg-black py-2 px-4 rounded transition-colors text-text-secondary-light bg-gradient-to-br from-gradient-blue to-gradient-purple font-light"
          >
            Skip
          </button>
        </div>
      </div>
    </NewView>
  )
}

export default Welcome;