import React, {useState} from 'react';
import Fields from '../common/Fields';

import NewView from "../common/NewView";

const EditNetizenViewMain = ({netizen, models, voices, onClickSave, onClickDelete}) => {
  const [activeNetizen, setActiveNetizen] = useState({
    id: netizen?.id,
    name: netizen?.name,
    description: netizen?.description,
    persona: netizen?.persona,
    model: netizen?.model?.id,
    voice: netizen?.voice?.id
  }
  );

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Netizen
            </h1>
          </div>
        </div>
      </div>

      <div className="h-full overflow-visible sm:overflow-scroll">
        <Fields.TextField label="Name" placeholder="Netizen Name" value={activeNetizen.name} onChange={(e) => {setActiveNetizen({...activeNetizen, name: e.target.value})}} />
        <Fields.DescriptionField label="Description" placeholder="Description" value={activeNetizen.description} onChange={(e) => {setActiveNetizen({...activeNetizen, description: e.target.value})}} />
        <Fields.DescriptionField label="Persona" placeholder="Persona" value={activeNetizen.persona} onChange={(e) => {setActiveNetizen({...activeNetizen, persona: e.target.value})}} />
        <Fields.SingleSelect
          label="Select Voice" 
          sublabel="Check the 'Voices' tab to listen to the available voices."
          info="(you can only select one)" 
          value={activeNetizen.voice} 
          onChange={(value) => {
            setActiveNetizen({...activeNetizen, voice: value})
          }} 
          options={
            [...voices]?.map(voice => ({
              value: voice.id,
              label: voice.name
            }))
          }
        />
        <Fields.SingleSelect
          label="Select Model" 
          sublabel="Check the 'Models' tab to see to the available models."
          info="(optional)" 
          value={activeNetizen.model} 
          onChange={(value) => {
            setActiveNetizen({...activeNetizen, model: value})
          }} 
          options={
            [{id: null, name: 'None'},...models]?.map(model => ({
              value: model.id,
              label: model.name
            }))
          }
        />
        <div className="flex-row space-x-4 mb-4 hidden sm:flex">
          <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeNetizen)}} />
          <Fields.Button type='danger' label='Delete' onClick={()=>{onClickDelete(activeNetizen)}} />
        </div>
        <div className="flex-row space-x-4 mb-4 flex sm:hidden">
          <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeNetizen)}} />
          <Fields.Button type='wide-danger' label='Delete' onClick={()=>{onClickDelete(activeNetizen)}} />
        </div>
      </div>
    </NewView>
  );
};

const EditNetizenView = ({
    netizen,
    models,
    voices,
    onClickSave,
    onClickDelete
    }) => {
  return (
    <EditNetizenViewMain netizen={netizen} models={models} voices={voices} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditNetizenView;