import React, { useState, useEffect, useCallback } from 'react';
import useScrollPercentage from 'react-scroll-percentage-hook';

import debounce from 'lodash/debounce';

// Components
import cx from 'classnames';
import './common.css';

// import img from "../assets/test/uploaded_image_1.png";

const BlurryEllipse = ({ 
  position = 'absolute',
  width, height, left, top, rotate = 27.66, 
  start_color = 'rgba(219, 0, 255, 0.5)', middle_color = 'rgba(219, 0, 255, 0.25)', end_color = 'var(--color-background)',
  z = '-1'
}) => {

  const blurry_ellipse = {
    position: `${position}`,
    width: `${width}`,
    height: `${height}`,
    left: `${left}`,
    top: `${top}`,
    background: `radial-gradient(50% 50% at center, ${start_color}, ${middle_color}, ${end_color})`,
    transform: `rotate(${rotate}deg)`,
    zIndex: `${z}`,
    overflow: 'visible'
  }

  return (
    <div style={blurry_ellipse} />
  );
}

const Input = ({ type = 'text', label, onChange, ...rest }) => {
  return (
    <div className="common-input-container">
      <span className="common-input-label">{label}</span>
      <input type={type} className="common-input" onChange={onChange} {...rest} />
    </div>
  );
}

const Button = ({ text, type, aspect=10, onClick }) => {

  var style = {
    aspectRatio: `${aspect}`,
  }

  return (
    <div className='common-button-container'>
      <button 
      type='button'
      style={style}
      className={cx(
        'common-button',
        type ? 'common-button-primary' : 'common-button-secondary'
       )}
      onClick={onClick}>
        <span className={cx(
          'common-button-text',
          type ? 'common-button-text-primary' : 'common-button-text-secondary'
        )}>{text}</span>
      </button>
    </div>
  );
}

const HeaderSpacer = () => {
  return (
    <div className="common-header-spacer" />
  );
}

const ImageGallery = ({images, onClick, loadMore = null}) => {

  // const images = [
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img,
  //   img
  // ]

  const { ref, percentage } = useScrollPercentage(
    {
      windowScroll: false
    }
  );

  // debounce
  const windowScrolled = useCallback(debounce((percentage) => {
    if(percentage && percentage.vertical > 80) {
      if(loadMore) {
        loadMore();
      }
    }
  }, 500), [])

  useEffect(() => {
    windowScrolled(percentage);
  }, [percentage]);

  const renderImages = () => {
    let img_elements = [];
    for (let i = 0; i < images.length; i++) {
      img_elements.push(
      <div key={i} className="common-imagegallery-item">
        <img className="common-imagegallery-image" src={images[i].src} alt='' />
        <span className="common-imagegallery-image-ellipse" onClick={() => onClick(images[i].id)} />
      </div>
      );
    }
    return img_elements;
  }

  return (
    <div ref={ref} className="common-imagegallery">
      {renderImages()}
    </div>
  );
}

export {
    BlurryEllipse,
    Input,
    Button,
    HeaderSpacer,
    ImageGallery
};