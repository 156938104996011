import React, {useState, useRef, useEffect} from 'react';

import Fields from '../common/Fields';

import globalConfig from '../../config/config';

const ModelPreview = ({ name='Name', description='Description', model, onUpload, onBack, uploadProgress }) => {
  const [activeModel, setActiveModel] = useState(model);
  const [modelValid, setModelValid] = useState(false);

  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  // post message to iframe
  useEffect(() => {
    if(iframeRef.current && activeModel && activeModel.file && iframeLoaded && activeModel.picture === undefined) {
      console.log('model', activeModel)

      const iframe = iframeRef.current;
      const file = activeModel.file;

      // read the data as a binary string
      const fileReader = new FileReader();
      fileReader.onload = function() {
        const raw = fileReader.result;

        console.log(`loaded ${raw.byteLength} bytes from file ${file.name}`);

        const data = {
          name: 'test.vrm',
          blob: true,
          raw: raw
        }

        iframe.contentWindow.postMessage(data, '*');
        console.log('sent file to iframe');
      }

      fileReader.readAsArrayBuffer(file);
    }
  }, [iframeRef, activeModel, iframeLoaded]);

  // receive message from iframe
  useEffect(() => {
    const handler = event => {
      const data = event.data

      // if blob is set to true, then it's the screenshot
      if(data && data.blob) {
        const raw = data.raw;
        console.log('Received screenshot from iframe of length:', raw.length);

        // save the screenshot in the model
        setActiveModel({...activeModel, picture: raw});

        // set the model as valid
        setModelValid(true);
      } else {
        console.log(`Received generic message from iframe: ${data}`)
      }
    }

    window.addEventListener("message", handler)

    // clean up
    return () => window.removeEventListener("message", handler)
  }, [])

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4 space-x-20">
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="text-lg font-regular m-0 mr-2">
                Model Preview
              </h1>
            </div>
          </div>
        </div>

          <div className="bg-white rounded-lg p-0 mb-6">

            <div className="w-full h-full overflow-hidden rounded-[12px]">
              <div className="relative w-full pb-[100.78%]"> {/* 16:9 aspect ratio */}
                <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                  <div className="w-[56.25vh] h-full"> {/* 9:16 aspect ratio */}
                    <iframe 
                      ref={iframeRef}
                      src={globalConfig.url + 'vrm_move/index.html'}
                      className="w-full h-full"
                      title="Model preview" 
                      onLoad={() => setIframeLoaded(true)}
                    />
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 h-40 bg-gradient-to-t from-black to-transparent" />

                <div className="absolute flex flex-col bottom-0 left-0 w-full p-4 space-y-4">
                  <div className="text-left text-white">
                    {name}
                  </div>
                  <div className="text-left text-sm text-white">
                    {description}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {uploadProgress > 0 && (
            <div className="mb-4">
              <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                  <div>
                    <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-gray-200">
                      {uploadProgress}%
                    </span>
                  </div>
                </div>
                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                  <div style={{ width: `${uploadProgress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-black"></div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row space-x-4">
            <Fields.Button type='wide-styled' label='Upload model' disabled={!modelValid} onClick={() => onUpload(activeModel)} />
            <Fields.Button type='wide-secondary' label='Back' onClick={onBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewModelView = ({
    name='Name',
    description='Description',
    model,
    uploadProgress,
    onUpload,
    onBack,
  }) => {
  return (
    <ModelPreview 
      name={name}
      description={description}
      model={model}
      uploadProgress={uploadProgress}
      onUpload={onUpload}
      onBack={onBack}
    />
  );
}

export default PreviewModelView;