import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

const NewNetizenViewMain = ({models, voices, onClickPreview}) => {
  const PERSONA_PLACEHOLDER = `\
* Name: Windsor
* Age: 30
* Gender: Male
* Home: Los Angeles
* Job: University student studying history
* Hobbies: Video games (both PC and console), library and story enthusiast, reading all sorts of books, watching history channels, loves my family
* Socials: Owns this Tiktok channel, which is a channel about interesting historical facts and stories
* Speaking Style: speaks like a very learned 30-year-old, with a smart and thoughtful, yet extremely opinionated, style of speaking.
He often spices up his conversations with cool facts and snippets from the historical stories and books he loves. His passion shines
through in his complex, cool, and very introspective way of talking, making him sound knowledgable to his Tiktok audience and friends alike.
In his narration, he shys away from alluding to facts and instead directly states them. He also tends to speak in the first person rather than
speaking for the audience.\
  `;
  const [activeNetizen, setActiveNetizen] = useState({
    model: null,
    voice: null,
  });
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (activeNetizen.name && activeNetizen.voice) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [activeNetizen]);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              New Netizen
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-visible sm:overflow-scroll">
        <Fields.TextField id='name' label="Name" placeholder="Netizen Name" value={activeNetizen.name} onChange={(e) => {setActiveNetizen({...activeNetizen, name: e.target.value})}} />
        {/* <Fields.DescriptionField id='description' label="Description" placeholder="Description" value={activeNetizen.description} onChange={(e) => {setActiveNetizen({...activeNetizen, description: e.target.value})}} /> */}
        <Fields.DescriptionField id='persona' label="Persona" placeholder={PERSONA_PLACEHOLDER} value={activeNetizen.persona} onChange={(e) => {setActiveNetizen({...activeNetizen, persona: e.target.value})}} />
        <Fields.SingleSelect
          id="voice"
          label="Select Voice" 
          sublabel="Check the 'Voices' tab to listen to the available voices."
          info="(you can only select one)" 
          value={activeNetizen.voice} 
          onChange={(value) => {
            setActiveNetizen({...activeNetizen, voice: value})
          }} 
          options={
            [...voices]?.map(voice => ({
              value: voice.id,
              label: voice.name
            }))
          }
        />
        <Fields.SingleSelect
          id="model"
          label="Select Model" 
          sublabel="Check the 'Models' tab to see to the available models."
          info="(optional)" 
          value={activeNetizen.model} 
          onChange={(value) => {
            setActiveNetizen({...activeNetizen, model: value})
          }} 
          options={
            [{id: null, name: 'None'},...models]?.map(model => ({
              value: model.id,
              label: model.name
            }))
          }
        />
        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button id='create' type='wide-styled' label='Create' disabled={!valid} disabledLabel='Fill in required fields...' onClick={()=>onClickPreview(activeNetizen)} />
        </div>
      </div>
    </NewView>
  );
};

const NewNetizenView = ({
    onClickPreview,
    models,
    voices,
    }) => {
  return (
    <NewNetizenViewMain models={models} voices={voices} onClickPreview={onClickPreview} />
  );
}

export default NewNetizenView;