import React, { useState, useEffect } from 'react';

import config from '../../config/config';

// services
import StripeService from '../../services/stripe.service';

const StripeCards = ({ info, sliderText = 'Pay', sliderHeader, onClick }) => {
  const [duration, setDuration] = useState('monthly');

  const toggleDuration = () => {
    if (duration === 'monthly') {
      setDuration('one-time');
    } else {
      setDuration('monthly');
    }
  }

  return (
    <div className="flex flex-col items-center justify-start h-full space-y-2">
      <span 
        className="membership-main-heading"
        style={{
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '32px',
          lineHeight: '140%',
      }}>
        {sliderHeader ? [sliderHeader, <br key={'br'} />] : null}
      </span>
      <span className="relative flex flex-row items-center justify-center bg-light-purple w-auto p-4 rounded-full cursor-pointer" onClick={() => {toggleDuration()}}>
        <div 
          className={`left-0 top-0 absolute bg-dark-purple w-1/2 h-full rounded-full z-0 transition-all duration-300 
          ${duration === 'monthly' ? 
              "ml-0" :
            duration === 'one-time' ?
              "ml-[50%]" :
              null
          }`}
        />
        <div className="z-1 flex flex-row space-x-16 h-full">
          <span className={`membership-duration-slider-left transition-all duration-300
            ${duration === 'monthly' ? 
              "text-white" :
            duration === 'one-time' ?
              "text-black" :
              null
          }`}>
          Monthly</span>
          <span className={`membership-duration-slider-right transition-all duration-300
          ${duration === 'monthly' ? 
              "text-black" :
            duration === 'one-time' ?
              "text-white" :
              null
          }`}>
          One-Time</span>
        </div>
      </span>
      <div className="flex flex-row space-x-4 p-4 mb-0">
        {info
        .filter((membership) => {
          return membership.type === duration;
        })
        .map((membership, i) => (
        <div key={i} className="flex flex-col space-y-4 w-1/3 bg-light-purple rounded-[30px] p-4 pb-3 items-center">
          <h4 className="text-md">{membership.visual.title}</h4>
          <span className="text-md font-semibold">{membership.visual.price}</span>
          <div className="flex flex-col space-y-2 items-start">
            {membership.visual.features.map((feature, j) => (
            <span key={j} className="text-sm font-normal">
              {j===membership.visual.features.length-1 ? feature ? '+ ' : '' : '* '}
              <span style={{ textDecoration: j===0 ? 'underline' : 'inhertit' }} className={j===0 ? 'text-dark-purple font-semibold' : 'text-black'}>
                {feature}
              </span>
            </span>
            ))}
          </div>
          <div className="flex flex-col space-x-4 space-y-1 mt-0 h-full bottom-0">
            <span className="h-[50px]">&nbsp;</span>
            <span 
              className="bg-dark-purple text-white mt-auto mb-1 p-3 rounded-full cursor-pointer hover:bg-outline-gray hover:!text-black transition-colors m-auto"
              onClick={() => { onClick(membership) }}
              >Choose {membership.visual.title}</span>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}

const Stripe = () => {
  const info = config.stripe.pricing;

  const onClick = async (membership) => {
    const priceId = membership.stripe.priceId;
    const mode = membership.stripe.mode;

    StripeService.startCheckout(
      priceId, 
      mode,
      `${config.url}settings`, // success url
      `${config.url}stripe` // failure url
      )
    .then((redirectUrl) => {
      window.location.href = redirectUrl
    })
  }

  return (
    <div className="flex flex-col items-center justify-start h-full space-y-4 overflow-scroll">
      <h3 className="text-black">Upgrade your <span className="membership-heading-stylized">membership</span> now</h3>
      <span className="text-sm">Rizzer.AI offer both a monthly subscription and a one-time payment option. You can change your subscription at any time.</span>
      <StripeCards info={info} onClick={onClick} />
    </div>
    );
};

export default Stripe;