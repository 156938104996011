import React, { useEffect, useState, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

import config from '../../config/config';
import axios from 'axios';
import { getAuth, authHeader } from '../../utils/auth';

const ContentChart = () => {
    const API_ENDPOINT_STATS = `${config.api}/stats`
    const START_DATE = new Date(2024, 7, 1);
    const END_DATE = new Date();

    const [stats, setStats] = useState([]);

    const [data, setData] = useState(null);

    // get job stats
    useEffect(() => {
        axios.get(API_ENDPOINT_STATS+'/jobs', 
            {
                headers: {
                    ...authHeader()
                }
            }
            )
            .then(response => {
                const stats = response.data
                setStats(stats);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    // create a different line per job type and accumulate by day
    useEffect(() => {
        if(stats.length === 0) {
            return;
        }

        const rawData = []

        // first loop, get all unique job types (in _id.type)
        stats.forEach(job => {
            if(!rawData.find(d => d.label === job._id.type)) {
                rawData.push({label: job._id.type, data: []});
            }
        })

        // second loop, accumulate by day into each job type

        let currentDate = new Date(START_DATE);
        while(currentDate <= END_DATE) {
            // _id.date is the date in "2024-08-16" format, count is the count that day

            rawData.forEach(job => {
                let total = 0;

                if(job.data.length > 0) {
                    total = job.data[job.data.length - 1].value;
                }

                total += stats.filter(u => new Date(u._id.date).toDateString() === currentDate.toDateString() && u._id.type === job.label).reduce((acc, curr) => acc + curr.count, 0);

                job.data.push({date: new Date(currentDate), value: total});
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        setData(rawData);
    }, [stats]);

    const primaryAxis = useMemo(
        () => ({
            getValue: datum => datum.date,
        }),
        []
    )

    const secondaryAxes = useMemo(
        () => [{
            getValue: datum => datum.value,
            elementType: 'line'
        }],
        []
    )

    return (
        <div className="flex flex-col w-full h-full">
            <h3>Job by Type</h3>
            {data && <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes
            }}
            />}
        </div>
    );
}

export default ContentChart;