import React, { useState, useEffect } from 'react';
import { Search, Plus } from 'lucide-react';

import Fields from '../common/Fields';
import EmptyView from '../common/EmptyView';

const ListViewCards = ({node, onClick}) => {
  return (
    <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 cursor-pointer'} onClick={()=>onClick(node)}>
      <div className={'px-3 flex flex-col w-full space-around'}>
        <div className="flex flex-row justify-start items-center space-x-2">
          <h3 className="text-black text-sm font-regular mb-0">{node?.properties?.subtype ? node.properties.subtype : '?'}:</h3>
          <span className="text-black text-xs font-regular mb-0">({node?.properties?.date ? `${node.properties.date[0]} - ${node.properties.date[1]}` : '?'})</span>
        </div>
        <p className="h-full text-gray text-xs mb-0">{node?.properties?.description ? node.properties.description : 'No description...'}</p>
      </div>
    </div>
  );
};

const ListView = ({nodes, onClick}) => {
  return (
    <div className="flex flex-col w-full">
      {nodes && nodes.length > 0 &&
      <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 px-3 mb-1 bg-other-gray'}>
        <span className={'w-[100%]'}></span>
      </div>}
      {nodes?.map((node, index) => (
        <ListViewCards key={index} node={node} onClick={onClick} />
      ))}
      {(!nodes || nodes.length === 0) && <div className="text-center text-gray-400 text-sm py-4">No memories found</div>}
    </div>
  );
};

const GridViewCards = ({node, onClick}) => {
  const MEDIA_PREFIX = 'https://cdn1.netizens.ai/'

  return (
    <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 cursor-pointer'} onClick={()=>onClick(node)}>
      <div className={'px-3 flex flex-col w-full space-around'}>
        <div className="grid grid-cols-2 gap-2">
          {node?.media_metadata?.map((item, index) => {
            if(item.type === 'video') return <Fields.VideoImage url={`${MEDIA_PREFIX}${item.s3}`} />
            if(item.type === 'image') return <Fields.Image url={`${MEDIA_PREFIX}${item.s3}`} />
            if(item.type === 'audio') return <Fields.Audio url={`${MEDIA_PREFIX}${item.s3}`} />
            return null
        })}
        </div>
        <div className="flex flex-row justify-start items-center space-x-2">
          <h3 className="text-black text-sm font-regular mb-0">{node?.properties?.subtype ? node.properties.subtype : '?'}:</h3>
        </div>
        <div className="flex flex-row justify-start items-center space-x-2">
          <span className="text-black text-xs font-regular mb-0">({node?.properties?.date ? `${node.properties.date[0]} - ${node.properties.date[1]}` : '?'})</span>
        </div>
        <p className="h-full text-gray text-xs mb-0">{node?.properties?.description ? node.properties.description : 'No description...'}</p>
      </div>
    </div>
  );
};

const GridView = ({nodes, onClick}) => {
  return (
    (nodes && nodes.length > 0) ? 
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {nodes?.map((node, index) => (
          <GridViewCards key={index} node={node} onClick={onClick} />
        ))}
      </div> :
      <div className="text-center text-gray-400 text-sm py-4">No memories found</div>
  );
};

const ExistingView = ({
    activeNetizen,
    netizens,
    nodes,
    title,
    description,
    info,
    label,
    onClickNetizen,
    onClickMemory,
    onClickCreate,
    footer,
  }) => {
  const [view, setView] = useState('grid');

  return (
    <div className="w-100 min-h-0 flex flex-col h-100 overflow-visible sm:overflow-hidden">
      <div className="container mx-auto px-0 py-0">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-4 sm:space-x-20 space-y-2 sm:space-y-auto">
          <div>
            <div className="flex items-center space-x-3 justify-between md:justify-start">
              <h1 className="text-lg font-regular m-0 mr-2">
                {title}
              </h1>
              <span className="bg-gray-200 text-black bg-light-purple text-[11px] font-regular px-2 py-1 rounded-full">
                {info}
              </span>
              <div className="flex flex-2 sm:hidden">&nbsp;</div>
              {onClickCreate && 
                <button 
                  className="flex sm:flex-none text-sm bg-black text-white px-1 py-1 rounded-[10px] flex sm:hidden items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
                  onClick={onClickCreate}
                >
                  <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
                  {label}
                </button>
              }
            </div>
            <p className="text-sm text-text-gray m-0">{description}</p>
          </div>
          <div className="flex items-center space-x-4 flex-1">
            <div className="relative flex flex-1">
              <input
                type="text"
                placeholder="Search here..."
                className="pl-10 pr-4 py-2 border rounded-[6px] w-64 bg-[#F9FAFB] flex-1"
                onKeyUp={null}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            {onClickCreate && <button 
              className="text-sm bg-black text-white px-3 py-2.5 rounded-[10px] hidden sm:flex items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
              onClick={onClickCreate}
            >
              <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
              {label}
            </button>}
          </div>
        </div>
        <div className="mb-14" />
      </div>

      <div className="container mx-auto px-0">
        <Fields.SingleSelect 
          label="Netizen" 
          sublabel="Who's memories are you viewing?"
          info="(pick only one)"
          options={netizens?.map(n => ({value: n.id, label: n.name}))}
          value={activeNetizen?.id}
          onChange={(value) => onClickNetizen(value)}
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-1">
        <h1 className="text-lg font-regular m-0 mr-2">
          Content List
        </h1>
        <div className="mb-2 bg-other-gray inline-flex py-[7.5px] px-[10.5px] rounded-[8px] font-semibold text-sm text-black">
          <button
            className={`min-w-40 px-4 py-[6px] rounded-[4px] transition-colors ${
              view === 'list'
                ? 'bg-light-purple'
                : 'hover:text-dark-purple font-normal'
            }`}
            onClick={() => setView('list')}
          >
            List view
          </button>
          <button
            className={`min-w-40 px-4 py-[6px] rounded-[4px] transition-colors ${
              view === 'grid'
                ? 'bg-light-purple'
                : 'hover:text-dark-purple font-normal'
            }`}
            onClick={() => setView('grid')}
          >
            Grid view
          </button>
        </div>
      </div>

      <div className="flex-grow overflow-visible sm:overflow-scroll">
        <div className="container mx-auto px-0">  
          {view==='list' && <ListView nodes={nodes} onClick={onClickMemory} />}
          {view==='grid' && <GridView nodes={nodes} onClick={onClickMemory} />}
        </div>
        {footer && <div className="bg-white py-4">
          <div className="mx-[10%] mb-[35px] border-t border-[#E4E7EC]" />
          <div className="container mx-auto px-4">
            <div className="flex flex-col m-0 space-y-1 text-center text-sm text-text-gray">
              {footer}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

const MemoriesView = ({
    activeNetizen,
    netizens,
    nodes,
    title,
    description,
    info,
    label,
    onClickNetizen,
    onClickMemory,
    onClickCreate,
    footer,
    emptyTitle='No models uploaded',
    emptyDescription='Click "Upload new model" button to get started in uploading your own new model seamlessly.',
    emptyLabel='Upload new model',
  }) => {
  return (
    <div className="min-h-0 flex bg-white m-0 flex-col items-center justify-center h-full" id="memories-view">
    {!netizens ? 
      <EmptyView 
          title={"Loading..."}
          description={"Please wait a moment..."}
      /> :
    netizens?.length === 0 ? 
      <EmptyView 
          title={emptyTitle}
          description={emptyDescription}
          label={emptyLabel}
          onClick={()=>{
            // go to /netizens on click
            window.location.href = '/netizens';
          }}
      /> : 
      <ExistingView 
        activeNetizen={activeNetizen}
        netizens={netizens}
        nodes={nodes}
        title={title}
        description={description}
        info={info}
        label={label}
        onClickNetizen={onClickNetizen}
        onClickMemory={onClickMemory}
        onClickCreate={onClickCreate}
        footer={footer}
      />
    }
    </div>
  );
}

export default MemoriesView;