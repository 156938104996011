import React, { useState, useRef, useEffect } from 'react';

const NetizenCard = ({ netizen, onClick }) => {
  const DEFAULT_MODEL_IMAGE = '/default_model.png';
  
  return (
    <div 
      className={`relative rounded-[13px] overflow-hidden aspect-[24/39] border border-light-purple \
        ${onClick ? "cursor-pointer hover:shadow-md transition-shadow" : ""}`} 
      onClick={onClick}>
      <img src={netizen?.model?.picture || DEFAULT_MODEL_IMAGE} alt={netizen.name} className="w-full h-full object-cover" />
      <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent"></div>
      <div className="flex px-[13px] py-[10px] absolute bottom-0 left-0 w-full h-2/5 flex-col">
        <h3 className="text-white text-sm font-semibold mb-2">{netizen.name}</h3>
        <p className="h-full text-white text-xs mb-0">{netizen.description}</p>
      </div>
    </div>
  )
};

export default NetizenCard;