import axios from "axios";
import config from "../config/config"

const API_ENDPOINT_AUTH = `${config.api}/auth`
const API_ENDPOINT_USER = `${config.api}/users`

const register = (name, email, password) => {
  return axios.post(API_ENDPOINT_AUTH + '/register', {
    name,
    email,
    password,
  });
};

const login = (email, password) => {
  return axios
    .post(API_ENDPOINT_AUTH + '/login', {
      email,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return null
    });
};

const loginGoogle = (code, redirect='http://localhost:8080') => {
  return axios
    .post(API_ENDPOINT_AUTH + '/loginOauth', {
      googleCode: code,
      redirect: redirect,
    })
    .then((response) => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
};

const loginFacebook = (code, redirect='http://localhost:8080') => {
  return axios
    .post(API_ENDPOINT_AUTH + '/loginOauth', {
      facebookCode: code,
      redirect: redirect,
    })
    .then((response) => {
      if (response.data.tokens.access.token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }

      return response.data;
    });
};

const refresh = (refreshToken) => {
  return axios
    .post(API_ENDPOINT_AUTH + '/refresh-tokens', {
      'refreshToken': refreshToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return null
    });
};

// get new user object
const getUser = (user_id, token) => {
  return axios
    .get(API_ENDPOINT_USER + '/' + user_id, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return null
    });
};

const logout = (refreshToken) => {
  return axios
    .post(API_ENDPOINT_AUTH + '/logout', {
      'refreshToken': refreshToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error)
      return null
    });

};

// send verification email
const sendVerificationEmail = async () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let token = user.tokens.access.token;

  if(!token) {
    return null
  }

  if(!user) {
    return null
  }
  
  return axios
    .post(API_ENDPOINT_AUTH + '/send-verification-email', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      // if successful response, return true. otherwise, return false
      return true
    })
    .catch((error) => {
      return false
    });
};

// verify email
const verifyEmail = async (token) => {
  return axios
    .get(API_ENDPOINT_AUTH + '/verify-email', {
      params: {
        token: token
      }
    })
    .then((response) => {
      // if successful response, return true. otherwise, return false
      return true
    })
    .catch((error) => {
      return false
    });
};

const AuthService = {
  register,
  login,
  loginGoogle,
  loginFacebook,
  getUser,
  logout,
  refresh,
  sendVerificationEmail,
  verifyEmail,
};

export default AuthService;
