import React, { useState, useRef, useEffect } from 'react';

import config from '../../config/config';

import ListView from '../common/ListView';
// import NewPipelineView from "./NewPipelineView";
// import PreviewPipelineView from "./PreviewPipelineView";
import AlertView from "../common/AlertView";
// import EditPipelineView from "./EditPipelineView";
import PipelineCard from './PipelineCard';
import NewPipelineView from './NewPipelineView';
import EditPipelineView from './EditPipelineView';

// tour
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps, LIFECYCLE } from 'react-joyride';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Pipelines = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_PIPELINES = `${globalConfig.api}/pipelines`
  const API_ENDPOINT_VOICES = `${globalConfig.api}/voices`
  const API_ENDPOINT_MODELS = `${globalConfig.api}/models`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  // get some info to know what joyride steps to show users
  const [joyrideRunning, setJoyrideRunning] = useState(true);
  const [joyrideActive, setJoyrideActive] = useState(true);
  const [steps, setSteps] = useState(config.joyride.pipelines);
  const [stepIndex, setStepIndex] = useState(0);

  const [view, setView] = useState('main');
  const [voices, setVoices] = useState(null);
  const [models, setModels] = useState(null);

  const [activePipeline, setActivePipeline] = useState(null);

  const [pipelines, setPipelines] = useState(null);
  const [pipelineCards, setPipelineCards] = useState(null);

  const [availablePipelines, setAvailablePipelines] = useState(null);
  const [availablePipelineCards, setAvailablePipelineCards] = useState(null);

  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [busy, setBusy] = useState(false);

  // joyride is only active when there are no pipelines
  useEffect(() => {
    if(pipelines && pipelines.length > 0) {
      console.log('pipelines exist, joyride inactive')
      setJoyrideActive(false);
    }
  }, [pipelines]);

  // callback
  const handleJoyrideCallback = (data) => {
    console.log('joyride pipelines callback')
    const { action, index, type, status, lifecycle } = data;
    console.log(data)

    if(lifecycle === LIFECYCLE.COMPLETE) {
      // for previous, go to previous step
      if (stepIndex > 1 && action === ACTIONS.PREV) {
        setStepIndex(Math.max(0, stepIndex - 1));
      }

      // for next, go to next step
      if ([1, 2, 3, 4].includes(stepIndex) && [ACTIONS.NEXT, ACTIONS.CLOSE, ACTIONS.SKIP].includes(action)) {
        setStepIndex(stepIndex + 1);
      }
    }
  }

  // set limit based on account type and pipelines
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.pipelines;

      if(curLimit < 0 || pipelines?.length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your pipeline limit ({pipelines?.length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} pipelines as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more pipelines.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${pipelines?.length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [pipelines]);

  useEffect(() => {
    loadAvailablePipelines()
    .then(() => {
      loadPipelines();
    });
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new-select':
        setBackEnabled(true);
        break;
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      switch(view) {
        case 'new-select':
          setView('main');
          setBackEvent(false);
          break;
        case 'new':
          setView('new-select');
          setBackEvent(false);
          break;
        default:
          setView('main');
          setBackEvent(false);
          break;
      }
    }
  }, [view, backEvent, setBackEvent]);

  // load current user's pipelines
  const loadPipelines = () => {
    axios.get(API_ENDPOINT_PIPELINES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user pipelines', response.data.results);
        const resPipelines = response.data.results
        setPipelines(resPipelines);
        setPipelineCards(resPipelines.map(m => 
          <PipelineCard 
            key={m.id} 
            pipeline={{
              ...m,
              credits: m.config.credits
            }}
            onClick={() => onCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load available pipelines for the current user
  const loadAvailablePipelines = () => {
    return axios.get(API_ENDPOINT_PIPELINES + '/available', {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded available pipelines', response.data);
        const resPipelines = response.data
        setAvailablePipelines(resPipelines);
        setAvailablePipelineCards(resPipelines.map(m => 
          <PipelineCard 
            key={m.id} 
            pipeline={{
              ...m,
              name: m.type,
              description: m.config.description,
              credits: m.config.credits,
            }} 
            onClick={() => onAvailableCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  }

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (pipeline) => {
    setActivePipeline(pipeline);
    setView('edit');
  }

  const onAvailableCardClick = (pipeline) => {
    setActivePipeline(pipeline);
    setView('new');
    setStepIndex(3);
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new-select');
      setStepIndex(1);
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickCreate = (pipeline) => {
    console.log('Creating pipeline...', pipeline);

    handleCreate(pipeline).then(() => {
      setMessage('You have successfully created a pipeline');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickPreview = (pipeline) => {
    console.log('Previewing pipeline...', pipeline);

    const newPipeline = {
      ...pipeline,
      model: models.find(m => m.id === pipeline.model),
      voice: voices.find(v => v.id === pipeline.voice)
    }

    setActivePipeline(newPipeline);
    setView('preview');
  }

  const onClickEdit = (pipeline) => {
    console.log(`Saving pipeline...`);
    console.log(pipeline);

    handleUpdate(pipeline).then(() => {
      setMessage('You have successfully updated the pipeline');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickTest = (pipeline) => {
    console.log(`Testing pipeline...${pipeline}`);
    return handleTest(pipeline);
  }

  const onClickDelete = (pipeline) => {
    console.log(`Deleting pipeline...${pipeline}`);

    handleDelete(pipeline).then(() => {
      setMessage('You have successfully deleted the pipeline');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  // handle testing a pipeline
  const handleTest = (pipeline) => {
    const body = {
      config: {
        ...pipeline.config,
        test: true
      }
    }
    
    return axios.post(`${API_ENDPOINT_PIPELINES}/${pipeline.id}/test`, 
      body, 
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // handle save pipeline
  const handleCreate = async (pipeline) => {
    if (pipeline) {
      let pipelineObject = {
        name: pipeline.name,
        description: pipeline.description,
        public: pipeline.public,
        type: pipeline.type,
        config: pipeline.config
      }

      // for every config field, if it's a dictionary and has a 'check' field that is set, set the value to null
      Object.keys(pipelineObject.config).forEach(key => {
        if (pipeline.schema[key] && pipeline.schema[key].check === true) {
          pipelineObject.config[key] = null;
        }
      });

      // if active pipeline is an available pipeline, create it
      return axios.post(API_ENDPOINT_PIPELINES, pipelineObject, {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload pipelines
        loadPipelines();

        // set user tour status if this was the first pipeline
        if(pipelines.length === 0) {
          const {user} = getAuth();
          let userTour = user.tour || {};
          const user_id = user.id;
          axios.patch(`${globalConfig.api}/users/${user_id}`, {
            tour: {
              ...userTour,
              pipelines: true
            }
          }, {
            headers: {
              ...authHeader()
            }
          })
          .then(response => {
            console.log('user tour updated');
          })
          .catch(error => {
            console.error(error);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  // update a pipeline
  const handleUpdate = (pipeline) => {
    if (pipeline) {
      let pipelineObject = {
        name: pipeline.name,
        description: pipeline.description,
        public: pipeline.public,
        type: pipeline.type,
        config: pipeline.config
      }

      // for every config field, if it's a dictionary and has a 'check' field that is set, set the value to null
      Object.keys(pipelineObject.config).forEach(key => {
        if (pipeline.schema[key] && pipeline.schema[key].check === true) {
          pipelineObject.config[key] = null;
        }
      });

      return axios.patch(`${API_ENDPOINT_PIPELINES}/${activePipeline.id}`, pipelineObject, {
          headers: {
            ...authHeader()
          }
        })
        .then(response => {
          // reload pipelines
          loadPipelines();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  // delete a pipeline
  const handleDelete = (pipeline) => {
    return axios.delete(`${API_ENDPOINT_PIPELINES}/${pipeline.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload pipelines
      loadPipelines();
    })
  }

  return [
    (joyrideActive && <Joyride
      run={joyrideRunning}
      continuous={false}
      showProgress={true}
      callback={handleJoyrideCallback}
      steps={steps}
      stepIndex={stepIndex}
    />),(
    (view==='main' && <ListView
      view={view}
      cards={pipelineCards} 
      toggle={false} 
      title="Pipelines"
      description="Find all the pipelines you have created here"
      info={tag}
      label="Create Pipeline"
      label1='Pipelines'
      label2='Credits'
      label3='Status'
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No pipelines created'
      emptyDescription='Click "Create new pipeline" button to get started generating content automatically.'
      emptyLabel='Create new pipeline'
      id='pipelines'
    />) ||
    (view==='new-select' && <ListView
      view={view}
      cards={availablePipelineCards} 
      toggle={false} 
      title="Select a Pipeline Template"
      description="You can further customize the pipeline after selecting a template"
      info={tag}
      label={null}
      label1='Pipeline Templates'
      label2='Credits'
      label3='Status'
      onClick={null}
      onSearch={onSearch}
      footer={footer}
    />) ||
    (view==='new' && <NewPipelineView
      pipelineTemplate={activePipeline}
      onClickCreate={onClickCreate}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) ||
    (view==='edit' && <EditPipelineView 
      pipeline={activePipeline}
      availablePipelines={availablePipelines}
      onClickSave={onClickEdit}
      onClickTest={onClickTest}
      onClickDelete={onClickDelete}
    />)
  )];
}

export default Pipelines;