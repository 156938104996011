import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const EditScheduleViewMain = ({schedule, netizens, pipelines, accounts, onClickSave, onClickDelete}) => {
  const API_ENDPOINT_SCHEDULES = `${globalConfig.api}/schedules`
  const S3_ROOT = 'https://cdn1.netizens.ai/'
  const DAYS_OF_THE_WEEK = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' }
  ]

  const [activeSchedule, setActiveSchedule] = useState(null);

  useEffect(() => {
    setActiveSchedule(schedule);
  }, [schedule]);

  // update specific field for active schedule
  const updateActiveScheduleField = (field, value) => {
    console.log('using schedule', activeSchedule);
    let newSchedule = {...activeSchedule};
    newSchedule[field] = value;
    setActiveSchedule(newSchedule);
  }

  // update the name of the active schedule
  const updateActiveScheduleName = (value) => {
    let newSchedule = {...activeSchedule};
    newSchedule.name = value;
    setActiveSchedule(newSchedule);
  }

  // update the description of the active schedule
  const updateActiveScheduleDescription = (value) => {
    let newSchedule = {...activeSchedule};
    newSchedule.description = value;
    setActiveSchedule(newSchedule);
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Schedule
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <div className="flex flex-col justify-start sm:pr-10">
          <Fields.TextField label="Name" placeholder="Schedule Name" value={activeSchedule?.name} onChange={(e) => {setActiveSchedule({...activeSchedule, name: e.target.value})}} />
          <Fields.DescriptionField label="Description" placeholder="Description" value={activeSchedule?.description} onChange={(e) => {setActiveSchedule({...activeSchedule, description: e.target.value})}} />
        </div>
        
        <div className="flex flex-col space-y-0">
          <div className="flex flex-row justify-between space-x-10 sm:pr-10">
            <div className="w-full flex-1">
              <Fields.MultiEnumField2
                label="Netizens" 
                sublabel="These are the netizens which will be involved in any pipelines run by the schedule."
                info={"(select multiple)"} 
                value={activeSchedule?.netizens}
                setValue={(value) => updateActiveScheduleField('netizens', value)} 
                values={
                  netizens?.map(n => ({
                    value: n.id,
                    label: n.name
                }))}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between space-x-10 sm:pr-10">
            <div className="w-full flex-1">
              <Fields.SingleSelect
                label="Pipeline" 
                sublabel="These is the pipelins that will be run every time the schedule is triggered."
                info={"(select only one)"} 
                value={activeSchedule?.pipeline}
                onChange={(value) => updateActiveScheduleField('pipeline', value)} 
                options={
                  pipelines?.map(n => ({
                    value: n.id,
                    label: n.name
                }))}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between space-x-10 sm:pr-10">
            <div className="w-full flex-1">
              <Fields.MultiEnumField2
                label="Accounts" 
                sublabel="These are the accounts that will be posted to once the pipelines generate content. Note, if the account was not configured as 'auto-publish', then you must manually publish the content via the Content Runs tab."
                info={"(select multiple)"} 
                value={activeSchedule?.accounts}
                setValue={(value) => updateActiveScheduleField('accounts', value)} 
                values={
                  accounts?.map(n => ({
                    value: n.id,
                    label: n.name
                }))}
              />
            </div>
          </div>

          <div className="flex flex-col sm:flex-row justify-between sm:space-x-10 sm:pr-10">
            <div className="w-full flex-1">
                  <Fields.DateField 
                    label="Start date" 
                    sublabel="The date the schedule will start running."
                    value={activeSchedule?.startDate} 
                    setValue={(value) => updateActiveScheduleField('startDate', value)} 
                  />
            </div>
            <div className="w-full flex-1">
                  <Fields.DateField 
                    label="End date" 
                    sublabel="The date the schedule will stop running."
                    value={activeSchedule?.endDate} 
                    setValue={(value) => updateActiveScheduleField('endDate', value)} 
                  />
            </div>
          </div>

          <div className="flex flex-row justify-between space-x-10 sm:pr-10">
            <div className="w-full flex-1">
              <Fields.MultiEnumField2
                label="Days of the week" 
                sublabel="These are the days of the week the schedule will run."
                info={"(can select multiple)"} 
                value={activeSchedule?.daysOfTheWeek}
                setValue={(value) => updateActiveScheduleField('daysOfTheWeek', value)} 
                values={DAYS_OF_THE_WEEK}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between space-x-10 sm:pr-10">
            {/* <div className="w-full flex-1">
              <Fields.IntField label="Times per day" value={activeSchedule?.timesPerDay} setValue={(value) => updateActiveScheduleField('timesPerDay', value)} />
            </div> */}
            <div className="w-full flex-1">
              <Fields.EnumField2 
                label="Enabled?" 
                sublabel="If you run out of credits, the schedule will automatically be disabled. You will need to manually re-enable it."
                value={activeSchedule?.active} 
                setValue={(value) => updateActiveScheduleField('active', value)} 
                values={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]} 
              />
            </div>
          </div>

        </div>

        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button type='wide-styled' label='Save' onClick={()=>onClickSave(activeSchedule)} />
          <Fields.Button type='wide-danger' label='Delete' onClick={()=>onClickDelete(activeSchedule)} />
        </div>
      </div>
    </NewView>
  );
};

const EditScheduleView = ({
    schedule, 
    netizens, 
    pipelines,
    accounts,
    onClickSave, 
    onClickDelete
    }) => {
  return (
    <EditScheduleViewMain schedule={schedule} netizens={netizens} pipelines={pipelines} accounts={accounts} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditScheduleView;