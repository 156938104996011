import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const EditPipelineViewMain = ({pipeline, availablePipelines, onClickSave, onClickTest, onClickDelete}) => {
  const API_ENDPOINT_PIPELINES = `${globalConfig.api}/pipelines`
  const S3_ROOT = 'https://cdn1.netizens.ai/'

  const [activePipeline, setActivePipeline] = useState(null);
  const [activePipelineTestJob, setActivePipelineTestJob] = useState(null);
  const [activePipelineTestJobReady, setActivePipelineTestJobReady] = useState(false);
  const [activePipelineTestJobUrl, setActivePipelineTestJobUrl] = useState(null);

  // runs ever 30 seconds, if there is an active pipeline set but no test job, load the test job
  useEffect(() => {
    const interval = setInterval(() => {
      if (activePipeline && !activePipelineTestJobReady) {
        console.log('timer loading test job');
        loadTestJob(activePipeline);
      }
    }, 30000);
    return () => clearInterval(interval);
  }, [activePipeline, activePipelineTestJobReady]);

  useEffect(() => {
    setActivePipelineByUserPipeline(pipeline);
  }, [pipeline]);

  // set the active pipeline by user pipeline selection
  const setActivePipelineByUserPipeline = (pipeline) => {
    // copy over schema
    let newPipeline = {...pipeline};

    // find the relevant available pipeline by type
    console.log('available pipelines', availablePipelines);
    let availablePipeline = availablePipelines.find(p => p.type === pipeline.type);

    // if no available pipelines, don't set active pipeline
    if (availablePipeline) {
      newPipeline.schema = availablePipeline.config.configurable;
      newPipeline.config.examples = availablePipeline?.config?.examples;
    } else {
      newPipeline.schema = null;
    }

    // if there's a schema, set checked fields
    if (newPipeline.schema) {
      Object.keys(newPipeline.schema).forEach(key => {
        if (newPipeline.schema[key].model === true && newPipeline.config[key] === null) {
          newPipeline.schema[key].check = true;
        }
      });
    }

    console.log('new pipeline', newPipeline);

    // set
    setActivePipeline(newPipeline);

    // load the test job
    loadTestJob(newPipeline);
  }

  // load the test job for the active pipeline
  const loadTestJob = (newPipeline) => {
    if (newPipeline) {
      axios.get(`${API_ENDPOINT_PIPELINES}/${newPipeline.id}/test`, {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        const latestJob = response.data;
        console.log('loaded test job', latestJob)
        setActivePipelineTestJob(latestJob);

        // set ready - check status
        let newStatus = false
        if (latestJob) {
          if (latestJob.status === 'COMPLETED') {
            newStatus = true;

            // if its completed, set the url
            
            // check for the first .mp4 file in config.output
            // config.output is a dictionary which may have any number
            // of nested dictionaries or lists, so we need to search for the first
            function findVideoUrl(obj) {
              console.log(obj)
              for (let key in obj) {
                if (obj[key].constructor === Object) {
                  return findVideoUrl(obj[key]);
                } else if (obj[key].constructor === Array) {
                  for (let i = 0; i < obj[key].length; i++) {
                    const _url = findVideoUrl(obj[key][i]);
                    if (_url) {
                      return _url;
                    }
                  }
                } else {
                  if (obj[key].endsWith('.mp4')) {
                    return obj[key];
                  }
                }
              }
            }
            
            try {
              let videoUrl = findVideoUrl(latestJob.config.output);
              videoUrl = S3_ROOT + videoUrl;

              console.log('video url', videoUrl);
              setActivePipelineTestJobUrl(videoUrl);
            } catch (error) {
              console.error(error);
              setActivePipelineTestJobUrl(null);
            }
          } else if (latestJob.status === 'FAILED') {
            newStatus = true; // lets you run it again
          } else if (latestJob.status === 'QUEUED' || latestJob.status === 'RUNNING' || latestJob.status === 'ERROR') {
            newStatus = false;
          } else {
            newStatus = true; // lets you run it again
          }
        }
        setActivePipelineTestJobReady(newStatus);
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  // run test job
  const runTestJob = () => {
    onClickTest(activePipeline).then(() => {
      // reload the test job
      loadTestJob(activePipeline);
    });
  }

  // update specific field for active pipeline
  const updateActivePipelineField = (field, value) => {
    let newPipeline = {...activePipeline};
    newPipeline.config[field] = value;
    setActivePipeline(newPipeline);
  }

  // set the check value of the active pipeline field
  const updateActivePipelineFieldCheck = (field, value) => {
    let newPipeline = {...activePipeline};
    newPipeline.schema[field].check = value;
    setActivePipeline(newPipeline);
  }

  // update the name of the active pipeline
  const updateActivePipelineName = (value) => {
    let newPipeline = {...activePipeline};
    newPipeline.name = value;
    setActivePipeline(newPipeline);
  }

  // update the description of the active pipeline
  const updateActivePipelineDescription = (value) => {
    let newPipeline = {...activePipeline};
    newPipeline.description = value;
    setActivePipeline(newPipeline);
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Pipeline
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <div className="flex flex-row justify-between pr-10 mb-4 overflow-scroll">
          {activePipeline?.config?.examples && activePipeline?.config?.examples.filter(e=>e.type==="youtube").slice(0,3).map((example, index) => {
            return (
              <div key={index} className="">
                <label className="text-sm font-semibold text-gray-500">Example {index+1}</label>
                <iframe
                  title={`example-video-${index}`}
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  src={example.url}
                />
              </div>
            )})
          }
        </div>

        <div className="flex flex-col justify-start pr-10">
          <Fields.TextField label="Pipeline Name" placeholder="Pipeline Name" value={activePipeline?.name} onChange={(e) => {setActivePipeline({...activePipeline, name: e.target.value})}} />
          <Fields.DescriptionField label="Description (optional)" placeholder="Description" value={activePipeline?.description} onChange={(e) => {setActivePipeline({...activePipeline, description: e.target.value})}} />
        </div>
        
        <div className="flex flex-col space-y-0">
        {activePipeline?.schema && activePipeline?.config && Object.keys(activePipeline?.schema).map((field, index) => {
          let field_type = activePipeline.schema[field].type;
          let field_sublabel = activePipeline.schema[field].description;
          let field_value = activePipeline.config[field];
          let field_values = activePipeline.schema[field].values;

          let field_check = activePipeline.schema[field].check;
          let field_check_enabled = activePipeline.schema[field].model;

          if(field_type === 'string') {
            return (
            <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
              <div className="w-full flex-1">
                <Fields.TextField 
                  label={field} 
                  sublabel={field_sublabel}
                  disabled={field_check_enabled ? field_check === true : false}
                  value={field_value}
                  onChange={(e) => updateActivePipelineField(field, e.target.value)} 
                  check={field_check_enabled ? field_check === true : false}
                  margin={false}
                />
              </div>
              <div className="flex flex-col flex-0 justify-center">
                <Fields.BoolField2 
                  label="let the netizen decide for you" 
                  value={field_check} 
                  setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                />
              </div>
            </div>
            )
          }
          if(field_type === 'int') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.IntField 
                    label={field}
                    sublabel={field_sublabel} 
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'float') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.FloatField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'bool' || field_type === 'boolean') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.BoolField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'date') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.DateField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if (field_type === 'enum') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.EnumField 
                    label={field} 
                    sublabel={field_sublabel}
                    info={"(select one)"} 
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    values={field_values} 
                    margin={false}
                  />
                </div>
              {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type.constructor === Object && field_type.type === 'enum') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.MultiEnumField 
                    label={field} 
                    sublabel={field_sublabel}
                    info={"(select multiple)"} 
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    values={field_values} 
                    margin={false}
                  />
                </div>
              {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          })}
        </div>

        {/* <div className="flex flex-row w-full justify-end">
          <div className="w-[20%]">
            <Fields.Video 
              label="Test Video" 
              info="This is a test video for the pipeline" 
              url={activePipelineTestJobUrl} 
            />
          </div>
        </div> */}

        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button 
            type='wide-styled' 
            label='Save' 
            onClick={()=>onClickSave(activePipeline)} 
          />
          {/* <Fields.Button 
            type='wide-styled' 
            label={(!activePipelineTestJob || (activePipelineTestJob && activePipelineTestJobReady)) ? 'Test Pipeline' : 'Waiting for test...'}
            disabled={!(activePipeline && activePipeline.schema !== null && (!activePipelineTestJob || (activePipelineTestJob && activePipelineTestJobReady)))}
            onClick={runTestJob} /> */}
          <Fields.Button 
            type='wide-danger' 
            label='Delete' 
            onClick={()=>onClickDelete(activePipeline)} 
          />
        </div>
      </div>
    </NewView>
  );
};

const EditPipelineView = ({
    pipeline, 
    availablePipelines,
    onClickSave, 
    onClickTest,
    onClickDelete
    }) => {
  return (
    <EditPipelineViewMain pipeline={pipeline} availablePipelines={availablePipelines} onClickSave={onClickSave} onClickTest={onClickTest} onClickDelete={onClickDelete} />
  );
}

export default EditPipelineView;