import React, { useEffect, useState, useMemo } from "react";
import { AxisOptions, Chart } from "react-charts";

import config from '../../config/config';
import axios from 'axios';
import { getAuth, authHeader } from '../../utils/auth';

const UserChart = () => {
    const API_ENDPOINT_STATS = `${config.api}/stats`
    const START_DATE = new Date(2024, 7, 1);
    const END_DATE = new Date();

    const [stats, setStats] = useState([]);

    const [totalUsers, setTotalUsers] = useState(null);
    const [verifiedUsers, setVerifiedUsers] = useState(null);

    // get all users
    useEffect(() => {
        axios.get(API_ENDPOINT_STATS+'/users', 
            {
                headers: {
                    ...authHeader()
                }
            }
            )
            .then(response => {
                const stats = response.data
                setStats(stats);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    // when users are loaded, create chart data for cumulative totalUsers and verifiedUsers by day
    useEffect(() => {
        if(stats.length === 0) {
            return;
        }

        const totalByDay = [];
        const verifiedByDay = [];

        let currentDate = new Date(START_DATE);
        while(currentDate <= END_DATE) {
            let total = 0;
            let verified = 0;

            if(totalByDay.length > 0) {
                total = totalByDay[totalByDay.length - 1].value;
                verified = verifiedByDay[verifiedByDay.length - 1].value;
            }

            // _id is the date in "2024-08-16" format, count & verifiedCount fields

            total += stats.filter(u => new Date(u._id).toDateString() === currentDate.toDateString()).reduce((acc, curr) => acc + curr.count, 0);
            verified += stats.filter(u => new Date(u._id).toDateString() === currentDate.toDateString()).reduce((acc, curr) => acc + curr.verifiedCount, 0);

            totalByDay.push({date: new Date(currentDate), value: total});
            verifiedByDay.push({date: new Date(currentDate), value: verified});

            currentDate.setDate(currentDate.getDate() + 1);
        }

        if(totalByDay.length > 0) {
            setTotalUsers([{label: 'Total Users', data: totalByDay}]);
        }

        if(verifiedByDay.length > 0) {
            setVerifiedUsers([{label: 'Verified Users', data: verifiedByDay}]);
        }
    }, [stats]);

    const data = useMemo(
        () => [...(totalUsers || []),...(verifiedUsers || [])],
        [totalUsers, verifiedUsers]
    )

    const primaryAxis = useMemo(
        () => ({
            getValue: datum => datum.date,
        }),
        []
    )

    const secondaryAxes = useMemo(
        () => [{
            getValue: datum => datum.value,
            elementType: 'line'
        }],
        []
    )

    return (
        <div className="flex flex-col w-full h-full">
            <h3>Signups by Type</h3>
            {totalUsers && <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes
            }}
            />}
        </div>
    );
}

export default UserChart;