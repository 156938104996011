import axios from "axios";
import config from "../config/config"

const API_URL = `${config.api}/stripe/`;

// get all payment intents
const getPaymentIntents = () => {
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  let token = tokens.access.token;
    
  return axios.get(
    `${API_URL}paymentintents`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    )
    .then((response) => {
      return response.data;
    }
    );
};

// get all subscriptions
const getSubscriptions = () => {
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  let token = tokens.access.token;
    
  return axios.get(
    `${API_URL}subscriptions`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    )
    .then((response) => {
      return response.data;
    }
    );
};

// cancel a subscription
const cancelSubscription = (subscriptionId) => {
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  let token = tokens.access.token;

  return axios.delete(
    `${API_URL}subscription/${subscriptionId}`,
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    )
    .then((response) => {
      return response.data;
    }
    );
};

// start checkout session
const startCheckout = (priceId, mode, successUrl, cancelUrl) => {
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  let token = tokens.access.token;

  return axios.post(
    `${API_URL}checkout`,
    {
      priceId,
      mode,
      success_url: successUrl,
      cancel_url: cancelUrl
    },
    {
      headers: {
        "Authorization": "Bearer " + token,
      },
    }
    )
    .then((response) => {
      return response.data;
    }
    );
};

const StripeService = {
  getPaymentIntents,
  getSubscriptions,
  cancelSubscription,
  startCheckout,
};

export default StripeService; 