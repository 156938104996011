import React from 'react';
import { CheckCircle } from 'lucide-react';

import NewView from './NewView';

const AlertViewMain = ({ title='Title', description='Description', Icon, label='Continue', onClick }) => {
  return (
    <div className="w-100 flex flex-col items-center justify-center h-screen bg-gray-50">
      <div className="bg-white p-8 rounded-lg text-center max-w-md w-full border rounded-[8px] border-[#E4E7EC]">
        <Icon className="w-16 h-16 text-green-500 mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-2">{title}</h2>
        <p className="text-gray-600 mb-6">{description}</p>
        <button
          onClick={onClick}
          className="w-full bg-black py-2 px-4 rounded transition-colors text-text-secondary-light bg-gradient-to-br from-gradient-blue to-gradient-purple font-light"
        >
          {label}
        </button>
      </div>
    </div>
  );
};

const AlertView = ({
    title='Title', 
    description='Description', 
    Icon=CheckCircle,
    label='Label', 
    onClick=()=>{}, 
    }) => {
  return (
    <NewView>
      <AlertViewMain 
        title={title}
        description={description}
        Icon={Icon}
        label={label}
        onClick={onClick}
      />
    </NewView>
  );
}

export default AlertView;