import React, {useState} from 'react';
import { Search, Plus } from 'lucide-react';
import EmptyView from '../common/EmptyView';

const ExistingView = ({cards=[], title='Title', description='Description', info='Premium 8/8', label='Label', label1='Address', label2='Last Run', label3='Times Run', onClick=()=>{}, onSearch=()=>{}, footer='Footer'}) => {
  return (
    <div className="w-100 min-h-0 flex flex-col h-100 overflow-visible sm:overflow-hidden">
      <div className="container mx-auto px-0 py-0">
        <div className="flex flex-col sm:flex-row justify-between sm:items-center mb-4 sm:space-x-20 space-y-2 sm:space-y-auto">
          <div>
            <div className="flex items-center space-x-3 justify-between md:justify-start">
              <h1 className="text-lg font-regular m-0 mr-2" id="list-view-title">
                {title}
              </h1>
              <span className="bg-gray-200 text-black bg-light-purple text-[11px] font-regular px-2 py-1 rounded-full">
                {info}
              </span>
              <div className="flex flex-2 sm:hidden">&nbsp;</div>
              {onClick && 
                <button 
                  className="flex sm:flex-none text-sm bg-black text-white px-1 py-1 rounded-[10px] flex sm:hidden items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
                  onClick={()=>onClick('new')}
                >
                  <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
                  {label}
                </button>
              }
            </div>
            <p className="text-sm text-text-gray m-0">{description}</p>
          </div>
          <div className="flex sm:items-center space-x-4 flex-1">
            <div className="relative flex flex-1">
              <input
                type="text"
                placeholder="Search here..."
                className="pl-10 pr-4 py-2 border rounded-[6px] w-64 bg-[#F9FAFB] flex-1"
                onKeyUp={onSearch}
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
            </div>
            {onClick && <button 
              className="text-sm bg-black text-white px-3 py-2.5 rounded-[10px] hidden sm:flex items-center bg-gradient-to-br from-gradient-blue to-gradient-purple font-normal"
              onClick={()=>onClick('new')}
            >
              <Plus size={16} className="p-0.5 mr-2 border-1 rounded-full border-[1.5px] border-white" color="white" />
              {label}
            </button>}
          </div>
        </div>
        <div className="mb-14" />
      </div>

      <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 px-3 mb-1 bg-other-gray'}>
        <div className={'flex flex-col w-[50%] space-around'}>
          <h2 className="text-black text-xs font-regular mb-0" id="list-view-label1">{label1}</h2>
        </div>
        <span className={'w-[25%]'}>{label2}</span>
        <span className={'w-[25%]'}>{label3}</span>
      </div>


      <div className="flex-grow overflow-auto">
        <div className="container mx-auto px-0">
          <>
            {cards.map((card, index) => (
              card
            ))}
          </>
        </div>

        {footer && <div className="bg-white py-4">
          <div className="mx-[10%] mb-[35px] border-t border-[#E4E7EC]" />
          <div className="container mx-auto px-4">
            <div className="flex flex-col m-0 space-y-1 text-center text-sm text-text-gray">
              {footer}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

const ListView = ({
    cards=[], 
    publicCards=[], 
    toggle=true, 
    title='Title', 
    description='Description', 
    info='Premium 8/8', 
    label='Label', 
    label1='Address',
    label2='Last Run',
    label3='Times Run',
    onClick=()=>{}, 
    onSearch=()=>{}, 
    footer='Footer',
    emptyTitle='No models uploaded',
    emptyDescription='Click "Upload new model" button to get started in uploading your own new model seamlessly.',
    emptyLabel='Upload new model',
    id
    }) => {
  return (
    <div className="min-h-0 flex bg-white m-0 flex-col items-center justify-center h-full" id={`list-view-${id}`}>
    {!cards ? 
      <EmptyView 
          title={"Loading..."}
          description={"Please wait a moment..."}
      /> :
    cards?.length === 0 ? 
      <EmptyView 
          title={emptyTitle}
          description={emptyDescription}
          label={emptyLabel}
          onClick={onClick}
      /> : 
      <ExistingView 
          cards={cards}
          publicCards={publicCards}
          toggle={toggle}
          title={title}
          description={description}
          info={info}
          label={label}
          label1={label1}
          label2={label2}
          label3={label3}
          onClick={onClick}
          onSearch={onSearch}
          footer={footer}
      />
    }
    </div>
  );
}

export default ListView;