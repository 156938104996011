import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import RunDetailViewYoutube from './RunDetailViewYoutube';
import RunDetailViewTiktok from './RunDetailViewTiktok';

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const RunDetailViewMain = () => {
  return (
    <NewView>
      <div className="flex flex-row h-full space-x-10 px-2 py-2 justify-around">
        Unsupported Social Media Account type
      </div>
    </NewView>
  );
};

const RunDetailView = ({
    job,
    onClickPost
    }) => {
      
    if(job.config.type === 'youtube') return <RunDetailViewYoutube job={job} onClickPost={onClickPost} />
    else if(job.config.type === 'tiktok') return <RunDetailViewTiktok job={job} onClickPost={onClickPost} />
    else return <RunDetailViewMain />
}

export default RunDetailView;