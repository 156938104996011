import React from 'react';

const NewViewMain = ({ children }) => {
  return (
    <div className="w-100 min-h-0 flex flex-col h-full">
      <div className="flex flex-col container mx-auto px-0 py-0 h-full">
        {children}
      </div>
    </div>
  );
};

// takes children and renders them in a flex container
const NewView = ({ children }) => {
  return (
    <div className="min-h-0 flex bg-white m-0 flex-col items-center justify-center h-full">
      <NewViewMain>
        {children}
      </NewViewMain>
    </div>
  );
}

export default NewView;