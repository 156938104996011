import React, { useState, useRef, useEffect } from 'react';

import { Play, Pause, Volume2 } from 'lucide-react';

const AudioControl = ({ label, audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  const audioRef = useRef(new Audio(audioUrl));

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      console.log('progress', audio.currentTime, audio.duration);

      // check if audio duration is infinity
      const realDuration = audio.duration === Infinity ? 60 * 1 : audio.duration;

      setProgress((audio.currentTime / realDuration) * 100);
    };

    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
    audio.addEventListener('ended', () => setIsPlaying(false));

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('ended', () => setIsPlaying(false));
      audio.pause();
    };
  }, []);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * audio.duration;
    audio.currentTime = newTime;
    setProgress(e.target.value);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="mb-2">
      <p className="text-sm text-black mb-1">{label}</p>
        <div className="flex items-center bg-gray-100 rounded-md p-2 mb-2">
          <button className="text-gray-700 hover:text-gray-900" onClick={(e) => {
            e.stopPropagation();
            togglePlay(audioRef.current, setIsPlaying)
            }}>
            {isPlaying ? <Pause size={18} /> : <Play size={18} />}
          </button>
          <div className="flex-grow mx-2 h-1 bg-white rounded-full">
            <div className="h-1 rounded-full bg-gradient-to-br from-gradient-blue to-gradient-purple font-light" style={{ width: `${progress}%` }}></div>
          </div>
          <button className="text-gray-700 hover:text-gray-900">
            <Volume2 size={18} />
          </button>
        </div>
    </div>
  );
};

const VoiceCard = ({ voice, onClick }) => {
  return (
    <div className="bg-[#FAFAFA] p-4 rounded-lg border border-[#EBEBEB] cursor-pointer hover:shadow-md transition-shadow" onClick={onClick}>
      {voice?.file && <AudioControl label="Original voice" audioUrl={voice?.file} />}
      {voice?.sample && <AudioControl label="Generated Sample" audioUrl={voice?.sample} />}
      {!voice?.sample && <div className="text-sm text-gray-500">No generated sample</div>}
      <div className="mt-4">
        <div className="w-full font-semibold text-black mb-2">{voice.name}</div>
        <div className="w-full text-text-gray">{voice.description}</div>
      </div>
    </div>
  );
};

export default VoiceCard;