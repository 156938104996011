import React, { useState, useEffect } from 'react';
import { Eye, EyeOff, Check } from 'lucide-react';

import AuthView from './AuthView';
import LoginFields from './LoginFields';

import config from '../../config/config';
import AuthService from '../../services/auth.service';

const ResetMain = () => {
  const [step, setStep] = useState(0);
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // check query string on load and set state to 2 if token is present
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token) {
      setToken(token);
      setStep(2);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    switch(step) {
      case 0:
        if(await sendCode()) {
          setStep(step + 1);
        }
        break;
      case 2:
        if(await updatePassword()) {
          setStep(step + 1);
        }
        break;
      case 3:
        window.location.href = '/login';
        break;
      default:
        break;
    }
  };

  const sendCode = async () => {
    return AuthService.forgotPassword(email)
      .then((data) => {
        return true
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        return false
      }
    );
  };

  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    return AuthService.resetPassword(token, newPassword)
      .then((data) => {
        return true
      })
      .catch((error) => {
        setMessage(error.response.data.message);
        return false
      }
    );
  };

  const renderStep = () => {
    switch(step) {
      case 0:
        return (
          <>
            <LoginFields.Header title="Reset password" />
            <LoginFields.Description description="Enter your credentials to recover password" />
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <LoginFields.Email email={email} setEmail={setEmail} />
              <LoginFields.Button label="Next" />
            </form>
          </>
        );
      case 1:
        return (
          <>
            <LoginFields.Header title="Reset password" />
            <LoginFields.Description description="Check the link in your email" />
          </>
        );
      case 2:
        return (
          <>
            <LoginFields.Header title="Reset password" />
            <LoginFields.Description description="Enter code sent to your email address to reset password." />
            {message && <LoginFields.Error error={message} />}
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <LoginFields.Password label="PASSWORD" placeholder="Enter Password" password={newPassword} setPassword={setNewPassword} />
              <LoginFields.Password label="CONFIRM PASSWORD" placeholder="Enter Password" password={confirmPassword} setPassword={setConfirmPassword} />
              <LoginFields.Button label="Reset password" />
            </form>
          </>
        );
      case 3:
        return (
          <>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-light-purple mb-4">
              <Check className="h-6 w-6 text-green-600" />
            </div>
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <LoginFields.Header title="Successful" />
              <LoginFields.Description description="You have successfully updated your password" />
              <LoginFields.Button label="Continue to Login" />
            </form>
          </>
        );
      default:
        return null;
    }
  };

  return renderStep()
};

const Reset = () => {
  return (
    <AuthView>
      <ResetMain />
    </AuthView>
  );
};

export default Reset;