import React, { useState, useEffect } from 'react';

import ProfileView from './ProfileView';

import axios from 'axios';
import globalConfig from '../../config/config';
import { updateUser, authHeader } from '../../utils/auth';

const Accounts = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_ACCOUNTS = `${globalConfig.api}/users`

  const [view, setView] = useState('profile');

  useEffect(() => {
    setBackEnabled(false);
  }, []);

  const onClickSave = (user) => {
    const newUser = {
      name: user.name,
      email: user.email,
      timezone: user.timezone,
    }

    axios.patch(`${API_ENDPOINT_ACCOUNTS}/${user.id}`, newUser, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      console.log('user updated', response.data);

      // refresh user info in cookies
      updateUser()
    })
    .catch(error => {
      console.error(error);
    });
  }


  return (
    (view==='profile' && <ProfileView
      onClickSave={(newUser) => {
        onClickSave(newUser);
      }}
    />)
  );
}

export default Accounts;