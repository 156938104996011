import React, { useState } from 'react';
import SimpleIcon from "../common/SimpleIcon";
import { siGoogle } from 'simple-icons';

import AuthView from './AuthView';
import LoginFields from './LoginFields';

import config from '../../config/config';
import AuthService from '../../services/auth.service';
import OAuth2Login from 'react-simple-oauth2-login';

import moment from 'moment-timezone';

const RegisterMain = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!email || !password) {
      setMessage('Please fill in all fields');
      return;
    }

    // name is part of email before the last @
    const name = email.split('@')[0];

    AuthService.register(name, email, password, moment.tz.guess())
      .then((data) => {
        if(data) {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));

          window.location.href = '/verify';
        } else {
          setMessage('Error registering');
        }
      })
      .catch((error) => {
        setMessage(error.response.data.message);
      })
  }

  const oauth2_onSuccessGoogle = (response) => {
    const code = response?.code

    setMessage(null);

    if (code) {
      AuthService.loginGoogle(
        code,
        (config.url+'/login').replace(/([^:]\/)\/+/g, "$1"),
        moment.tz.guess()
        ).then(
        (data) => {
          // Save the token and user info in local storage
          localStorage.setItem('tokens', JSON.stringify(data.tokens));
          localStorage.setItem('user', JSON.stringify(data.user));
          
          window.location.href = '/netizens';
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
        }
      );
    } else {
      setMessage('Registration disallowed, try again later.');
    }
  };

  const oauth2_onFailure = response => console.error(response);

  return (
    <div>
      <LoginFields.Header title="Create an Account" />
      <LoginFields.Description description="Enter your credentials to access your account" />
      {message && <LoginFields.Error error={message} />}
      <form className="mt-8 space-y-6" action="#" method="POST">
        <div className="space-y-4">
          <LoginFields.Email label="EMAIL ADDRESS" placeholder="Enter Email" email={email} setEmail={setEmail} />
          <LoginFields.Password label="PASSWORD" placeholder="Enter Password" password={password} setPassword={setPassword} />
          <div className="pt-6">
            <LoginFields.Button label="Create Account" onClick={handleSubmit} />
          </div>
        </div>
      </form>

      <div className="mt-6">
        <LoginFields.DashedText text="Or" />
        <div className="mt-6">
          <OAuth2Login
            authorizationUrl={config.google.authorization_url}
            responseType="code"
            clientId={config.google.client_id}
            redirectUri={(config.url+'/login').replace(/([^:]\/)\/+/g, "$1")}
            scope={config.google.scopes.join(' ')}
            onSuccess={oauth2_onSuccessGoogle}
            onFailure={oauth2_onFailure}
            render={({ onClick }) => {return (
                <LoginFields.Button 
                  label="Register with Google" 
                  type="google" 
                  icon={<SimpleIcon icon={siGoogle} className="h-5 w-5 mr-2" />} 
                  onClick={onClick}
                />
              )}
            }
          />
        </div>
      </div>

      <div className="mt-6 text-center text-sm">
        <span className="text-text-gray">Already have an account? </span>
        <LoginFields.Link label="Log in" link="login" />
      </div>
    </div>
  );
};

const Register = () => {
  return (
    <AuthView>
      <RegisterMain />
    </AuthView>
  );
};

export default Register;