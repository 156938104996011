import React, {useEffect, useState} from 'react';

import CardView from '../common/CardView';
import NewModelView from "./NewModelView";
import PreviewModelView from "./PreviewModelView";
import AlertView from "../common/AlertView";
import EditModelView from "./EditModelView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const ModelCard = ({ model, onClick }) => (
  <div 
    className={`relative rounded-[13px] overflow-hidden aspect-[24/39] border border-light-purple \
      ${onClick ? "cursor-pointer hover:shadow-md transition-shadow" : ""}`} 
    onClick={onClick}>
    <img src={model.picture} alt={model.name} className="w-full h-full object-cover" />
    <div className="absolute bottom-0 left-0 w-full h-1/2 bg-gradient-to-t from-black to-transparent"></div>
    <div className="flex px-[13px] py-[10px] absolute bottom-0 left-0 w-full h-2/5 flex-col">
      <h3 className="text-white text-sm font-semibold mb-2">{model.name}</h3>
      <p className="h-full text-white text-xs mb-0">{model.description}</p>
    </div>
  </div>
);

const Models = ({backEvent, setBackEvent, setBackEnabled}) => {
  const DEFAULT_MODEL_IMAGE = '/default_model.png';
  const API_ENDPOINT_MODELS = `${globalConfig.api}/models`

  const [view, setView] = useState('main');
  const [models, setModels] = useState(null);
  const [activeModel, setActiveModel] = useState(null);
  const [modelCards, setModelCards] = useState(null);
  const [publicModelCards, setPublicModelCards] = useState(null);
  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [busy, setBusy] = useState(false);

  // set limit based on account type and models
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.models;

      if(curLimit < 0 || models?.filter(m=>m.owner).length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your model limit ({models?.filter(m=>m.owner).length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} models as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more models.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${models?.filter(m=>m.owner).length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [models]);

  useEffect(() => {
    loadModels();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      setView('main');
      setBackEvent(false);
    }
  }, [backEvent, setBackEvent]);

  // load available models for the current user
  const loadModels = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    axios.get(API_ENDPOINT_MODELS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resModels = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            picture: m.picture ? m.picture : DEFAULT_MODEL_IMAGE,
            public: m.public,
            file: m.file,
            owner: m.owner === user_id
          }
        })

        console.log(resModels);
        
        // set models
        setModels(resModels);

        // set model cards for public/private
        setModelCards(resModels.filter(m => m.owner).map(m => <ModelCard key={m.id} model={m} onClick={() => onCardClick(m)} />));
        setPublicModelCards(resModels.filter(m => !m.owner).map(m => <ModelCard key={m.id} model={m} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (model) => {
    setActiveModel(model);
    setView('edit');
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickPreview = (model) => {
    console.log('Previewing model...', model);
    setActiveModel(model);
    setView('preview');
  }

  const onClickUpload = (model) => {
    console.log(`Uploading model...${model}`);

    handleUpload(model).then(() => {
      setMessage('You have successfully uploaded a model');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickEdit = (model) => {
    console.log(`Saving model...${model}`);

    handleUpdate(model).then(() => {
      setMessage('You have successfully updated the model');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickDelete = (model) => {
    console.log(`Deleting model...${model}`);

    handleDelete(model).then(() => {
      setMessage('You have successfully deleted the model');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  // create a new model
  const handleUpload = (model) => {
    // Reset progress
    setUploadProgress(0);

    // Create form data
    const formData = new FormData();
    formData.append('file', model.file);
    formData.append('name', model.name);
    formData.append('description', model.description);
    formData.append('picture', model.picture);
    formData.append('public', false);

    // set busy state
    setBusy(true);

    // post to endpoint
    return axios.post(API_ENDPOINT_MODELS, formData, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 80) / progressEvent.total);
        setUploadProgress(percentCompleted);
      },
    })
    .then(response => {
      setUploadProgress(100);

      // reload models
      loadModels();

      // reset busy state
      setBusy(false);
    })
    .catch(error => {
      console.error(error);

      // reset busy state
      setBusy(false);

      throw error;
    });
  };

  // update a model
  const handleUpdate = (model) => {
    return axios.patch(
      `${API_ENDPOINT_MODELS}/${model.id}`, 
      {
        name: model.name,
        description: model.description,
        public: model.public
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload models
        loadModels();
      })
  }

  // delete a model
  const handleDelete = (model) => {
    return axios.delete(`${API_ENDPOINT_MODELS}/${model.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload models
      loadModels();
    })
  }

  return (
    (view==='main' && <CardView
      view={view}
      cards={modelCards} 
      publicCards={publicModelCards} 
      toggle={true} 
      title="Models"
      description="Find all the models you have created here"
      info={tag}
      label="Create Model"
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No models created'
      emptyDescription='Click "Create new model" button to get started generating content automatically.'
      emptyLabel='Create new model'
      emptyEnabled={false}
    />) ||
    (view==='new' && <NewModelView
      onClickPreview={onClickPreview}
    />) ||
    (view==='preview' && <PreviewModelView 
      name={'Storia'}
      description={'A model created using vroidstudio'}
      model={activeModel}
      uploadProgress={uploadProgress}
      onUpload={onClickUpload}
      onBack={()=>setView('new')}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />) || 
    (view==='edit' && <EditModelView 
      model={activeModel}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
    />)
  );
}

export default Models;