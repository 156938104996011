import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

import axios from 'axios';
import globalConfig from '../../config/config';
import { authHeader, updateUser } from '../../utils/auth';

const RunDetailViewMain = ({job, onClickPost}) => {
  const API_ENDPOINT_JOBS = `${globalConfig.api}/renderjobs`
  const API_ENDPOINT_ACCOUNTS = `${globalConfig.api}connectedaccounts`

  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`


  const [info, setInfo] = useState({});
  const [message, setMessage] = useState('');
  const [renderjob, setRenderjob] = useState(null);
  const [accounts, setAccounts] = useState(null);

  const [media, setMedia] = useState(null);

  // get media from renderjob
  useEffect(() => {
    const media_s3 = renderjob?.config?.output?.result?.map((r) => {{
      return r?.media?.s3
    }}).filter((m) => m);

    const title = renderjob?.config?.output?.result?.map((r) => {{
      return r?.title
    }}).filter((m) => m)[0];

    const description = renderjob?.config?.output?.result?.map((r) => {{
      return r?.description
    }}).filter((m) => m)[0];

    if(media_s3 && media_s3.length > 0) {
      const fullUrl = `${S3_PATH_PREFIX}/${media_s3[0]}`;
      console.log('media', fullUrl);
      setMedia(fullUrl);
    }

    setInfo({
      ...info,
      title: title,
      description: description
    })
  }, [renderjob]);

  // get renderjob for a job
  useEffect(() => {
    const renderjob_id = job?.config?.renderjob;

    if(renderjob_id) {
      axios.get(`${API_ENDPOINT_JOBS}/${renderjob_id}`, {
        headers: {
          ...authHeader()
        }
      }).then((response) => {
        console.log('renderjob', response.data);
        setRenderjob(response.data);
      });
    }
  }, [job]);
  
  // get accounts for a job
  useEffect(() => {
    const account_ids = job?.config?.accounts;

    // for each account_id, get the account
    if(account_ids) {
      const promises = account_ids.map((account_id) => {
        return axios.get(`${API_ENDPOINT_ACCOUNTS}/${account_id}`, {
          headers: {
            ...authHeader()
          }
        }).catch((error) => {
          console.log('error', error);
        });
      });

      Promise.all(promises)
      .then((responses) => {
        const accounts = responses.map((response) => {
          return response?.data;
        });

        // only keep defined accounts that are youtube
        setAccounts(accounts.filter((a) => a).filter((a) => a.type === 'youtube'));
      });
    }
  }, [job]);

  const updateInfo = (field, value) => {
    setInfo({...info, [field]: value});
  }

  useEffect(() => {
    console.log(info);
  }, [info]);

  return (
    <NewView>
      <div className="flex flex-col sm:flex-row h-full space-y-4 sm:space-y-auto sm:space-x-10 px-2 py-2 justify-around">
        <div className="w-auto sm:flex-1 h-1/2 sm:h-auto overflow-scroll">
          <Fields.Video label="Your&nbsp;video&nbsp;is&nbsp;ready!" info="Get&nbsp;ready&nbsp;to&nbsp;post" url={media} />
        </div>
        <div className="flex flex-col sm:flex-2 sm:w-3/5 h-full overflow-scroll">
          <Fields.TextField label="User account" value={accounts?.map(a=>a?.name).filter(a=>a)} disabled={true} onChange={()=>{}} />
          <Fields.DescriptionField label="Title" placeholder="Add a title that describes your video" value={info?.title} onChange={(e) => updateInfo('title', e.target.value)} />
          <Fields.DescriptionField label="Description" placeholder="Add a description" value={info?.description} onChange={(e) => updateInfo('description', e.target.value)} />
          <br />
          <Fields.Button type='wide-styled' label='Post' onClick={()=>onClickPost(job, info)} />
        </div>
      </div>
    </NewView>
  );
};

const RunDetailView = ({
    job,
    onClickPost
    }) => {
  return (
    <RunDetailViewMain job={job} onClickPost={onClickPost} />
  );
}

export default RunDetailView;