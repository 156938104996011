import React, { useState } from 'react';
import { Mail, Eye, EyeOff } from 'lucide-react';

const Header = ({items}) => {
  return (
    <header className="w-full bg-white">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 pt-3">
        <div className="w-[90%] sm:m-auto ml-0 flex justify-between items-center h-16">
          <h1 className="min-w-[20%] my-auto sm:ml-2 ml-0 text-lg font-medium text-black">NETIZENS.ai</h1>
          <nav className="my-auto hidden sm:flex sm:space-x-8 bg-other-gray rounded-full">
            {items.map((item) => (
              <a
                key={item.label}
                href={item.href}
                className="text-text-gray-dark hover:text-dark-purple px-3 py-2 text-xs font-medium"
              >
                {item.label}
              </a>
            ))}
          </nav>
          <div className="min-w-[20%] my-auto flex items-center space-x-4">
            {/* <a href="login">
              <button className="px-4 py-2 rounded-full border border-gray-300 text-gray-700 hover:bg-gray-50 text-xs font-medium">
                Log in
              </button>
            </a>
            <a href="register">
              <button className="px-4 py-2 rounded-full bg-black text-white hover:bg-gray-800 text-xs font-medium">
                Get started
              </button>
            </a> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;