import React, { useState, useEffect} from 'react';
import Fields from '../common/Fields';
import LoginFields from '../Auth/LoginFields';
import SettingsView from "./SettingsView";

import { getAuth, authHeader } from '../../utils/auth';
import moment from 'moment-timezone';

const ProfileViewMain = ({onClickSave}) => {
  const [activeUser, setActiveUser] = useState(null);

  // load user object into state
  useEffect(() => {
    const {user} = getAuth();
    setActiveUser(user);
  }, []);

  return (
    <SettingsView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Account Settings
            </h1>
          </div>
        </div>
      </div>

      <div className="space-y-5">
        <LoginFields.Name name={activeUser?.name} setName={(value) => {setActiveUser({...activeUser, name: value})}} />
        <Fields.EnumDropdown 
          label="Timezone" 
          value={activeUser?.timezone} 
          setValue={(value) => {setActiveUser({...activeUser, timezone: value})}} 
          values={moment.tz.names().map(n=>{
            return {
              label: n,
              value: n
            }})}
        />
        <span className='text-lg font-bold'>Video Walkthrough: <a href="https://youtu.be/zQK9N58v_ek?si=0COF8Ns6gzGIaPA5" className='text-md font-normal text-dark-purple underline'>YouTube Link</a></span>
      </div>

      <div className="flex flex-row space-x-4 my-4">
        <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeUser)}} />
      </div>
    </SettingsView>
  );
};

const ProfileView = ({onClickSave}) => {
  return (
    <ProfileViewMain onClickSave={onClickSave} />
  );
}

export default ProfileView;