import React, { useState, useEffect } from 'react';

import config from '../../config/config';

import ListView from '../common/ListView';
import NewAccountView from "./NewAccountView";
import AlertView from "../common/AlertView";
import EditAccountView from "./EditAccountView";
import AccountCard from './AccountCard';

// tour
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps, LIFECYCLE } from 'react-joyride';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Accounts = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_ACCOUNTS = `${globalConfig.api}/connectedaccounts`

  // get some info to know what joyride steps to show users
  const [joyrideRunning, setJoyrideRunning] = useState(true);
  const [joyrideActive, setJoyrideActive] = useState(true);
  const [steps, setSteps] = useState(config.joyride.accounts);
  const [stepIndex, setStepIndex] = useState(0);

  const [view, setView] = useState('main');
  const [accounts, setAccounts] = useState(null);
  const [activeAccount, setActiveAccount] = useState(null);
  const [accountCards, setAccountCards] = useState(null);
  const [message, setMessage] = useState('');
  
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  // joyride is only active when there are no netizens
  useEffect(() => {
    if(accounts && accounts.length > 0) {
      setJoyrideActive(false);
    }
  }, [accounts]);

  // callback
  const handleJoyrideCallback = (data) => {
    console.log('joyride accounts callback')
    const { action, index, type, status, lifecycle } = data;
    console.log(data)

    if(lifecycle === LIFECYCLE.COMPLETE) {
      // for previous, go to previous step
      if (stepIndex > 1 && action === ACTIONS.PREV) {
        setStepIndex(Math.max(0, stepIndex - 1));
      }

      // for next, go to next step
      if ([1, 2].includes(stepIndex) && [ACTIONS.NEXT, ACTIONS.CLOSE, ACTIONS.SKIP].includes(action)) {
        setStepIndex(stepIndex + 1);
      }
    }
  }

  // set limit based on account type and connected accounts
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.accounts;

      if(curLimit < 0 || accounts?.length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your account limit ({accounts?.length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} accounts as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more accounts.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${accounts?.length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [accounts]);

  useEffect(() => {
    loadAccounts();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      setView('main');
      setBackEvent(false);
    }
  }, [backEvent, setBackEvent]);

  // load current user's accounts
  const loadAccounts = () => {
    axios.get(API_ENDPOINT_ACCOUNTS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user accounts', response.data.results);

        const resAccounts = response.data.results

        setAccounts(resAccounts);
        setAccountCards(resAccounts.map(m => <AccountCard key={m.id} account={m} onClick={() => onCardClick(m)} />));
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onCardClick = (account) => {
    setActiveAccount(account);
    setView('edit');
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
      setStepIndex(1);
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickAuthorize = () => {
    console.log(`Created account...`);
    
    setMessage('You have successfully linked an account');
    setView('success');

    const {user} = getAuth();
    let userTour = user.tour || {};

    // set user tour status if this was the first netizen
    if(!userTour.accounts) {
      const user_id = user.id;
      axios.patch(`${globalConfig.api}/users/${user_id}`, {
        tour: {
          ...userTour,
          accounts: true
        }
      }, {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        console.log('user tour updated');
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  const onClickEdit = (account) => {
    console.log(`Saving account...`);
    console.log(account);

    handleUpdate(account).then(() => {
      setMessage('You have successfully updated the account');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickDelete = (account) => {
    console.log(`Deleting account...${account}`);

    handleDelete(account).then(() => {
      setMessage('You have successfully deleted the account');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  // update a account
  const handleUpdate = (account) => {
    return axios.patch(
      `${API_ENDPOINT_ACCOUNTS}/${account.id}`, 
      {
        name: account.name,
        description: account.description,
        publish: account.publish,
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload accounts
        loadAccounts();
      })
  }

  // delete a account
  const handleDelete = (account) => {
    return axios.delete(`${API_ENDPOINT_ACCOUNTS}/${account.id}`, {
      headers: {
        ...authHeader()
      }
    })
    .then(response => {
      // reload accounts
      loadAccounts();
    })
  }

  return [
    (joyrideActive && <Joyride
      run={joyrideRunning}
      continuous={false}
      showProgress={true}
      callback={handleJoyrideCallback}
      steps={steps}
      stepIndex={stepIndex}
    />),(
    (view==='main' && <ListView
      view={view}
      cards={accountCards}
      title="Accounts"
      description="Find all the accounts you have created here"
      info={tag}
      label="Create Account"
      label1=''
      label2='Type'
      label3='Auto-publish'
      onClick={onClickNew}
      onSearch={onSearch}
      footer={footer}
      emptyTitle='No accounts linked'
      emptyDescription='Click "Link new account" button to get started generating content automatically.'
      emptyLabel='Link new account'
    />) ||
    (view==='new' && <NewAccountView
      onClickAuthorize={onClickAuthorize}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>{
        loadAccounts();
        setView('main')
      }}
    />) || 
    (view==='edit' && <EditAccountView 
      account={activeAccount}
      onClickSave={onClickEdit}
      onClickDelete={onClickDelete}
    />)
  )];
}

export default Accounts;