import React, {useState, useRef, useEffect} from 'react';

import Fields from '../common/Fields';
import NetizenCard from './NetizenCard';

import globalConfig from '../../config/config';

const NetizenPreview = ({ name='Name', description='Description', netizen, onUpload, onBack, uploadProgress }) => {
  const [modifiedNetizen, setModifiedNetizen] = useState(null);

  useEffect(() => {
    setModifiedNetizen({
      ...netizen,
      model: netizen?.model?.id,
      voice: netizen?.voice?.id,
    });
  }, [netizen]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4 space-x-20">
            <div>
              <div className="flex items-center space-x-3">
                <h1 className="text-lg font-regular m-0 mr-2">
                  Netizen Preview
                </h1>
              </div>
            </div>
          </div>

          <div className="flex h-[500px] justify-center bg-white rounded-lg p-0 mb-6">
            <NetizenCard netizen={netizen} />
          </div>

          <div className="flex flex-row space-x-4">
            <Fields.Button type='wide-styled' label='Create netizen' onClick={() => onUpload(modifiedNetizen)} />
            <Fields.Button type='wide-secondary' label='Back' onClick={onBack} />
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewNetizenView = ({
    name='Name',
    description='Description',
    netizen,
    uploadProgress,
    onUpload,
    onBack,
  }) => {
  return (
    <NetizenPreview 
      name={name}
      description={description}
      netizen={netizen}
      uploadProgress={uploadProgress}
      onUpload={onUpload}
      onBack={onBack}
    />
  );
}

export default PreviewNetizenView;