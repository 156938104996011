import React from 'react';
import Header from './Header';

const AuthViewMain = ({ children }) => {
  return (
    <div className="flex-grow flex items-start sm:items-center justify-center py-[0px] sm:py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 bg-white px-10 pt-[0px] pb-[20px] sm:pt-10 sm:pb-10 rounded-lg border border-outline-gray">
        {children}
      </div>
    </div>
  );
};

// takes children and renders them in a flex container
const AuthView = ({ children }) => {
  const items = [
    { label: 'Home', href: '/' },
    { label: 'Features', href: '/#features' },
    { label: 'Pricing', href: '/#pricing' },
    { label: 'About us', href: '/#about-us' },
    { label: 'Contact us', href: '/#contact-us' },
  ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
        <Header items={items} />
        <AuthViewMain>
            {children}
        </AuthViewMain>
    </div>
  );
}

export default AuthView;