import React, { useState, useRef, useEffect } from 'react';

const ScheduleCard = ({ schedule, onClick }) => {
  const formatDate = (date, day=true) => {
    const formattedDate = new Date(date || 0).toLocaleString('en-US', { month: day ? '2-digit' : undefined, day: day ? '2-digit' : undefined, hour: 'numeric', minute: 'numeric', hour12: true }).replace(',', '@');
    return formattedDate;
  }

  return (
    <div className={'h-auto flex flex-row w-full border-b border-outline-gray items-center text-xs py-3 cursor-pointer'} onClick={onClick}>
      <div className={'px-3 flex flex-col w-[50%] space-around'}>
        <h3 className="text-black text-sm font-regular mb-0">{schedule.name}</h3>
        <p className="h-full text-gray text-xs mb-0">{schedule.description}</p>
      </div>
      <span className={'w-[25%]'}>{schedule.lastRun ? formatDate(schedule.lastRun) : ''}</span>
      <span className={'w-[25%]'}>{schedule.nextRun ? formatDate(schedule.nextRun) : ''}</span>
    </div>
  )
};

export default ScheduleCard;