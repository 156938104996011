import axios from "axios";
import config from "../config/config"

const API_ENDPOINT_CONNECTEDACCOUNTS = `${config.api}/connectedaccounts`

const createConnectedaccountByYoutube = (name, description, type, code, redirect='http://localhost:3001') => {
  let tokens = JSON.parse(localStorage.getItem('tokens'));
  let token = tokens.access.token;

  if(!token) {
    return null
  }

  return axios
    .post(API_ENDPOINT_CONNECTEDACCOUNTS, {
      name: name,
      description: description,
      type: type,
      youtubeCode: code,
      redirect: redirect,
    },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      return response.data;
    });
};

const ConnectedaccountService = {
  createConnectedaccountByYoutube
};

export default ConnectedaccountService;
