import React, { useState, useRef, useEffect } from 'react';

import MemoriesView from './MemoriesView';
import AlertView from "../common/AlertView";
import MemoryCard from './MemoryCard';
import EditMemoryView from './EditMemoryView';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Memorys = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_NETIZENS = `${globalConfig.api}/netizens`
  const API_ENDPOINT_GRAPHS = `${globalConfig.api}/graphs`
  const API_ENDPOINT_NODES = `${globalConfig.api}/nodes`
  const API_ENDPOINT_MEDIA = `${globalConfig.api}/media`

  const [view, setView] = useState('main');
  const [netizens, setNetizens] = useState(null);

  const [graph, setGraph] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [media, setMedia] = useState(null);

  const [activeNetizen, setActiveNetizen] = useState(null);
  const [activeNodeIndex, setActiveNodeIndex] = useState(null);

  const [mediaProgress, setMediaProgress] = useState(null);

  const [message, setMessage] = useState('');
  const [limit, setLimit] = useState(true);
  const [tag, setTag] = useState(null);
  const [footer, setFooter] = useState([]);

  // set limit based on account type and memories
  useEffect(() => {
    const limits = globalConfig.limits;
    const auth = getAuth();
    const role = auth?.user?.role;

    const roleLimits = limits[role];
    if(roleLimits) {
      const curLimit = roleLimits.memories;

      if(curLimit < 0 || nodes?.length < curLimit) {
        setLimit(false);
        setFooter([]);
      } else {
        setLimit(true);
        setFooter([
          <span>You've reached your memory limit ({nodes?.length || 0}/{curLimit<0?'∞':curLimit}) you are only allowed to have {curLimit<0?'∞':curLimit} memories as a {roleLimits.label} user</span>,
          <span>When you upgrade your plan, you can create more memories.</span>
        ]);
      }

      setTag(`${roleLimits.label} ${nodes?.length || 0}/${curLimit<0?'∞':curLimit}`);
    } else {
      setLimit(true);
      setFooter([]);
    }
  }, [nodes]);

  // on mount
  useEffect(() => {
    const loadAll = async () => {
      // load all user netizens
      loadNetizens();
    }

    loadAll();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'new':
        setBackEnabled(true);
        break;
      case 'preview':
        setBackEnabled(false);
        break;
      case 'success':
        setBackEnabled(false);
        break;
      case 'edit':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      switch(view) {
        case 'new':
          setView('main');
          setBackEvent(false);
          break;
        default:
          setView('main');
          setBackEvent(false);
          break;
      }
    }
  }, [view, backEvent, setBackEvent]);

  // load current user's netizens (must be owned)
  const loadNetizens = () => {
    const {user} = getAuth();
    const user_id = user.id;

    axios.get(API_ENDPOINT_NETIZENS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        // filter out netizens that are not owned by the user
        let user_netizens = response.data.results.filter(netizen => netizen.owner === user_id);
        console.log('loaded netizens', user_netizens);
        setNetizens(user_netizens);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // create a new memory
  const createMemory = () => {
    // create a new node
    return axios.post(API_ENDPOINT_NODES, {
      graph: graph.id,
      type: 'experience',
      properties: {
        subtype: 'human',
        description: 'new memory',
        date: [(new Date()).toISOString().split('.')[0].replace('T', ' '), (new Date()).toISOString().split('.')[0].replace('T', ' ')]
      }
      }, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('created node', response.data);
        setNodes([...nodes, response.data]);
        setActiveNodeIndex(nodes.length);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // on active netizen change get the graph (or create a new one and give it to the netizen)
  useEffect(() => {
    loadNodes();
  }, [activeNetizen]);

  // load the nodes
  const loadNodes = () => {
    if(activeNetizen) {
      console.log('Loading nodes for active netizen', activeNetizen);
      // lookup the active netizen
      const active_netizen = netizens.find(netizen => netizen.id === activeNetizen.id);

      if (!active_netizen) {
        console.error('active netizen not found', activeNetizen.id);
        return;
      }

      console.log('active netizen changed', active_netizen);
      const graph_id = active_netizen.graph;

      if(graph_id) {
        // load the graph
        console.log('netizen has a graph, loading it', graph_id)
        axios.get(`${API_ENDPOINT_GRAPHS}/${graph_id}`, {
          headers: {
            ...authHeader()
          }})
          .then(response => {
            console.log('loaded graph', response.data);
            setGraph(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        console.log('netizen does not have a graph, creating one');
        // create a new graph
        axios.post(API_ENDPOINT_GRAPHS, {}, {
          headers: {
            ...authHeader()
          }})
          .then(response => {
            console.log('graph created, giving id to netizen', response.data);

            // update the netizen with the new graph id
            axios.patch(`${API_ENDPOINT_NETIZENS}/${active_netizen.id}`, {
              graph: response.data.id
              }, {
              headers: {
                ...authHeader()
              }})
              .then(response => {
                console.log('updated netizen with graph id', response.data);

                // update both the netizen list and the active netizen
                setNetizens(netizens.map(netizen => netizen.id === activeNetizen.id ? response.data : netizen));
                setActiveNetizen(response.data);
              })
              .catch(error => {
                console.error(error);
              });

            setGraph(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }

  // on graph change get the nodes that are of type experience
  useEffect(() => {
    if(graph) {
      // load the nodes
      axios.get(`${API_ENDPOINT_NODES}?graph=${graph.id}&type=experience`, {
        headers: {
          ...authHeader()
        }})
        .then(response => {
          console.log('loaded nodes', response.data);
          setNodes(response.data.results);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [graph]);

  // on active node change get the media
  useEffect(() => {
    if(activeNodeIndex !== null) {
      const activeNode = nodes[activeNodeIndex];
      if(activeNode && activeNode.properties && activeNode.properties.data && activeNode.properties.data.length > 0) {
        const media_ids = activeNode.properties.data

        axios.get(`${API_ENDPOINT_MEDIA}?ids[]=${media_ids.join('&ids[]=')}`, {
          headers: {
            ...authHeader()
          }})
          .then(response => {
            console.log('loaded media', response.data.results);
            setMedia(response.data.results.reverse());
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, [activeNodeIndex]);

  // whenever node list changes, try to get first 4 media items that are not text for each node and populate the media_metadata field
  useEffect(() => {
    if(nodes) {
      let media_ids_to_query = nodes.map(node => node?.properties?.data?.slice(0, 4)).flat().filter(id => id);

      // if an object with id is already in the media_metadata, don't query it
      nodes.forEach(node => {
        if(node.media_metadata) {
          node.media_metadata.forEach(media_item => {
            media_ids_to_query = media_ids_to_query.filter(id => id !== media_item.id);
          });
        }
      });

      console.log('media ids to query', media_ids_to_query);
      
      if(media_ids_to_query.length > 0) {
        axios.get(`${API_ENDPOINT_MEDIA}?ids[]=${media_ids_to_query.join('&ids[]=')}`, {
          headers: {
            ...authHeader()
          }})
          .then(response => {
            let newNodes = [...nodes];

            newNodes.forEach(node => {
              if(node.properties.data) {
                node.properties.data.forEach(id => {
                  const media_item = response.data.results.find(item => item.id === id);
                  if(media_item) {
                    node.media_metadata = node.media_metadata ? [...node.media_metadata, media_item] : [media_item];
                  }
                });
              }
            });
            setNodes([...newNodes]);
          })
          .catch(error => {
            console.error(error);
          });
    }}
  }, [nodes]);

  // save memory
  const updateMemory = (memory) => {
    const new_properties = memory.properties;

    return axios.patch(`${API_ENDPOINT_NODES}/${memory.id}`, {
      properties: new_properties
      }, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('updated node', response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // delete memory
  const deleteMemory = (oldNode) => {
    return axios.delete(`${API_ENDPOINT_NODES}/${oldNode.id}`, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('deleted node', response.data);
        setActiveNodeIndex(null);
        setNodes(nodes.filter(node => node.id !== oldNode.id));
      })
      .catch(error => {
        console.error(error);
      });
  }

  // update media
  const updateMedia = (index) => {
    const activeMedia = media[index];
    console.log('updating media', activeMedia);

    return axios.patch(`${API_ENDPOINT_MEDIA}/${activeMedia.id}`, {
      description: activeMedia.description
      }, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('updated media', response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  // delete media
  const deleteMedia = (oldMedia) => {
    console.log('deleting media', oldMedia);

    // remove it from the memory
    return axios.delete(`${API_ENDPOINT_NODES}/${nodes[activeNodeIndex].id}/media`, {
      headers: {
        ...authHeader()
      },
      data: {
        media: [oldMedia.id]
      }})
      .then(response => {
        console.log('removed media from memory', response.data);

        // remove it from the media list
        setMedia(media.filter(item => item.id !== oldMedia.id));
      })
      .catch(error => {
        console.error(error);
      });
  }

  // create media and add to memory (also update progress)
  const createMedia = (newMedia) => {
    const activeNode = nodes[activeNodeIndex];
    console.log('new media', newMedia);
    console.log('creating media for node', activeNode);

    const data = new FormData();
    data.append('type', newMedia.type);

    if (newMedia.description) {
      data.append('description', newMedia.description);
    }

    if (newMedia.type === 'video' || newMedia.type === 'audio' || newMedia.type === 'image') {
      if (!newMedia.files || newMedia.files.length === 0) {
        console.error('no files selected');
        return;
      }

      for (let i = 0; i < newMedia.files.length; i++) {
        data.append('files[]', newMedia.files[i]);
      }
    }

    setMediaProgress(0);

    axios.post(API_ENDPOINT_MEDIA, data, {
      headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        setMediaProgress(Math.min(percentCompleted, 90));
      }
    })
    .then(media_response => {
      console.log('created media', media_response.data);

      // add the media to the memory
      axios.patch(`${API_ENDPOINT_NODES}/${activeNode.id}/media`, {
        media: media_response.data.map(item => item.id)
        }, {
        headers: {
          ...authHeader()
        }})
        .then(response => {
          console.log('added media to memory', response.data);
          
          // update the node's media list
          nodes[activeNodeIndex].properties.data = response.data.properties.data;
          setNodes([...nodes]);

          // update the media list
          if(media) {
            setMedia([...(media_response.data.reverse()), ...media]);
          } else {
            setMedia(media_response.data.reverse());
          }

          // clear the progress bar
          setMediaProgress(null);
        })
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onMemoryCardClick = (memory) => {
    console.log('Memory clicked', memory);
    
    setView('edit');
  }

  const onNetizenCardClick = (netizen) => {
    console.log('Netizen clicked', netizen);
  }

  const onClickNew = (e) => {
    if(!limit) {
      setView('new');
    } else {
      alert('Upgrade your plan.');
    }
  };

  const onClickCreateMemory = (memory) => {
    console.log('Creating memory...', memory);

    if(!limit) {
      createMemory().then(() => {
        //
      })
      .catch(error => {
        console.error(error);
      });
    } else {
      alert('Upgrade your plan.');
    }
  }

  const onClickSaveMemory = (memory) => {
    console.log(`Saving memory...`);
    console.log(memory);

    updateMemory(memory).then(() => {
      loadNodes();
      setMessage('You have successfully updated the memory');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickDeleteMemory = (memory) => {
    console.log(`Deleting memory...${memory}`);
    console.log(memory);

    deleteMemory(memory).then(() => {
      setMessage('You have successfully deleted the memory');
      setView('success');
    })
    .catch(error => {
      console.error(error);
    });
  }

  const onClickAddMedia = (media) => {
    console.log('Adding media...', media);

    createMedia(media);
  }

  const onClickDeleteMedia = (media) => {
    console.log('Deleting media...', media);

    deleteMedia(media);
  }

  const onClickNetizen = (netizen) => {
    console.log('Netizen clicked', netizen);
    const newNetizen = netizens.find(n => n.id === netizen);
    setActiveNetizen(newNetizen);
  }

  const onClickMemory = (node) => {
    console.log('Node clicked', node);
    setActiveNodeIndex(nodes.findIndex(n => n.id === node.id));
    setView('edit');
  }

  return (
    (view==='main' && <MemoriesView
      activeNetizen={activeNetizen}
      netizens={netizens}
      nodes={nodes}
      title="Memories"
      description="Find all the memories you have created here"
      info={tag}
      label="Create Memory"
      onClickNetizen={onClickNetizen}
      onClickMemory={onClickMemory}
      onClickCreate={onClickCreateMemory}
      footer={footer}
      emptyTitle='No netizens created yet'
      emptyDescription='Click "Create new netizen" button to get started generating content automatically.'
      emptyLabel='Create new netizen'
    />) ||
    (view==='edit' && <EditMemoryView 
      node={nodes[activeNodeIndex]}
      media={media}
      mediaProgress={mediaProgress}
      onClickSaveMemory={onClickSaveMemory}
      onClickDeleteMemory={onClickDeleteMemory}
      onClickAddMedia={onClickAddMedia}
      onClickDeleteMedia={onClickDeleteMedia}
    />) ||
    (view==='success' && <AlertView
      title={"Successful"}
      description={message}
      label={"Continue"}
      onClick={()=>setView('main')}
    />)
  );
}

export default Memorys;