import React, {useState} from 'react';
import Fields from '../common/Fields';

import NewView from "../common/NewView";

const EditModelViewMain = ({model, onClickSave, onClickDelete}) => {
  const [activeModel, setActiveModel] = useState(model);

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Edit Model
            </h1>
          </div>
        </div>
      </div>

      <Fields.TextField label="Name" placeholder="Model's Name" value={activeModel.name} onChange={(e) => {setActiveModel({...activeModel, name: e.target.value})}} />
      <Fields.DescriptionField label="Description" placeholder="Description" value={activeModel.description} onChange={(e) => {setActiveModel({...activeModel, description: e.target.value})}} />
      <Fields.EnumField2 label="Public?" info="Decide whether other people can use this model" value={activeModel.public} setValue={(value) => {setActiveModel({...activeModel, public: value})}} values={[
        {label: 'Yes', value: true},
        {label: 'No', value: false}
      ]} />
      <div className="flex flex-row space-x-4 mb-4">
        <Fields.Button type='wide-styled' label='Save' onClick={()=>{onClickSave(activeModel)}} />
        <Fields.Button type='danger' label='Delete' onClick={()=>{onClickDelete(activeModel)}} />
      </div>
    </NewView>
  );
};

const EditModelView = ({
    model,
    onClickSave,
    onClickDelete
    }) => {
  return (
    <EditModelViewMain model={model} onClickSave={onClickSave} onClickDelete={onClickDelete} />
  );
}

export default EditModelView;