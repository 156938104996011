import React, { useState, useEffect } from 'react';

import AuthView from './AuthView';
import LoginFields from './LoginFields';

import AuthService from '../../services/auth.service';

// when the page loads
// simply grab the token from the URL and send it to the server to verify
// set page to success or fail based on the response
const Verify = () => {
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('Verifying email...');

  // check query string on load
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token) {
      AuthService.verifyEmail(token)
        .then((data) => {
          if(data) {
            setStatus('success');
          } else {
            setStatus('error');
          }
        });
    } else {
      setMessage('Want to resend the verification email?');
      setStatus('resend');
    }
  }, []);

  const onSuccess = () => {
    window.location.href = 'login';
  }

  const onResend = () => {
    AuthService.sendVerificationEmail()
      .then((data) => {
        if(data) {
          setMessage('Email resent');
          setStatus('loading');
        } else {
          setMessage('Error resending email');
          setStatus('error');
        }
      });
  }

  return (
    <AuthView>
      <LoginFields.Header title="Verification" />
      <LoginFields.Description description={message} />
      {status==='error' && <LoginFields.Error error="Error verifying email" />}
      {status==='success' && <LoginFields.Button label="Success" onClick={onSuccess} />}
      {status==='resend' && <LoginFields.Button label="Resend" onClick={onResend} />}
    </AuthView>
  );
};

export default Verify;