import React, { useState, useEffect} from 'react';
import { useReactMediaRecorder } from "react-media-recorder-2";
import Fields from '../common/Fields';
import NewView from "../common/NewView";

const NewPipelineViewMain = ({pipelineTemplate, onClickCreate}) => {
  const [activePipeline, setActivePipeline] = useState(null);
  const [activePipelineTestJob, setActivePipelineTestJob] = useState(null);
  const [activePipelineTestJobReady, setActivePipelineTestJobReady] = useState(false);
  const [activePipelineTestJobUrl, setActivePipelineTestJobUrl] = useState(null);

  useEffect(() => {
    setActivePipelineByAvailablePipeline(pipelineTemplate);
  }, [pipelineTemplate]);

  // set the active pipeline by available pipeline selection
  const setActivePipelineByAvailablePipeline = (pipeline) => {
    function changeTimezone(date, ianatz) {

      // suppose the date is 12:00 UTC
      var invdate = new Date(date.toLocaleString('en-US', {
        timeZone: ianatz
      }));
    
      // then invdate will be 07:00 in Toronto
      // and the diff is 5 hours
      var diff = date.getTime() - invdate.getTime();
    
      // so 12:00 in Toronto is 17:00 UTC
      return new Date(date.getTime() - diff); // needs to substract
    
    }

    let newPipeline = {
      name: `New ${pipeline.type} Pipeline`,
      description: '',
      public: false,
      type: pipeline.type,
      schema: pipeline.config.configurable,
      config: {}
    }

    // copy over all non-configurable fields
    Object.keys(pipeline.config).forEach(key => {
      if (key !== 'configurable' && key !== 'schema' && key !== 'description' && key !== 'public' && key !== 'type' && key !== 'owner' && key !== 'name') {
        newPipeline.config[key] = pipeline.config[key];
      }
    });

    // for configurable fields, copy them over (with optional default values)
    Object.keys(pipeline.config.configurable).forEach(key => {
      let field = pipeline.config.configurable[key];
      let field_type = field.type;

      // types can be "string", "int", "float", "bool", "enum", or another dictionary (for array)
      if (field_type === 'string') {
        newPipeline.config[key] = field.default || '';
      } else if (field_type === 'int') {
        newPipeline.config[key] = field.default || 0;
      } else if (field_type === 'float') {
        newPipeline.config[key] = field.default || 0.0;
      } else if (field_type === 'bool') {
        newPipeline.config[key] = field.default || false;
      } else if (field_type === 'date') {
        //newPipeline.config[key] = changeTimezone(new Date(field.default), "America/Los_Angeles") || new Date();
        newPipeline.config[key] = new Date(field.default) || new Date();
      } else if (field_type === 'enum') {
        newPipeline.config[key] = field.default || field.values[0];
      } else if (field_type.constructor === Object) {
        // if array, default value is still in field.default
        newPipeline.config[key] = field.default || [];
      } else {
        // unsupported
      }
    });

    console.log(newPipeline);

    setActivePipeline(newPipeline);

    // clear test info
    setActivePipelineTestJob(null);
    setActivePipelineTestJobReady(false);
    setActivePipelineTestJobUrl(null);
  }

  // update specific field for active pipeline
  const updateActivePipelineField = (field, value) => {
    let newPipeline = {...activePipeline};
    newPipeline.config[field] = value;
    setActivePipeline(newPipeline);
  }

  // set the check value of the active pipeline field
  const updateActivePipelineFieldCheck = (field, value) => {
    let newPipeline = {...activePipeline};
    newPipeline.schema[field].check = value;
    setActivePipeline(newPipeline);
  }

  // update the name of the active pipeline
  const updateActivePipelineName = (value) => {
    let newPipeline = {...activePipeline};
    newPipeline.name = value;
    setActivePipeline(newPipeline);
  }

  // update the description of the active pipeline
  const updateActivePipelineDescription = (value) => {
    let newPipeline = {...activePipeline};
    newPipeline.description = value;
    setActivePipeline(newPipeline);
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              New Pipeline
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <div className="flex flex-row justify-between pr-10 mb-4 overflow-scroll">
          {activePipeline?.config?.examples && activePipeline?.config?.examples.filter(e=>e.type==="youtube").slice(0,3).map((example, index) => {
            return (
              <div key={index} className="">
                <label className="text-sm font-semibold text-gray-500">Example {index+1}</label>
                <iframe
                  title={`example-video-${index}`}
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  src={example.url}
                />
              </div>
            )})
          }
        </div>

        <div className="flex flex-col justify-start pr-10">
          <Fields.TextField label="Pipeline Name" placeholder="Pipeline Name" value={activePipeline?.name} onChange={(e) => {setActivePipeline({...activePipeline, name: e.target.value})}} id="pipelinename"/>
          {/* <Fields.DescriptionField label="Description" placeholder="Description" value={activePipeline?.description} onChange={(e) => {setActivePipeline({...activePipeline, description: e.target.value})}} /> */}
        </div>
        
        <div className="flex flex-col space-y-0">
        {activePipeline?.schema && activePipeline?.config && Object.keys(activePipeline?.schema).map((field, index) => {
          let field_type = activePipeline.schema[field].type;
          let field_sublabel = activePipeline.schema[field].description;
          let field_value = activePipeline.config[field];
          let field_values = activePipeline.schema[field].values;

          let field_check = activePipeline.schema[field].check;
          let field_check_enabled = activePipeline.schema[field].model;

          if(field_type === 'string') {
            return (
            <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
              <div className="w-full flex-1">
                <Fields.TextField 
                  label={field} 
                  sublabel={field_sublabel}
                  disabled={field_check_enabled ? field_check === true : false}
                  value={field_value}
                  onChange={(e) => updateActivePipelineField(field, e.target.value)} 
                  check={field_check_enabled ? field_check === true : false}
                  margin={false}
                />
              </div>
              <div className="flex flex-col flex-0 justify-center">
                <Fields.BoolField2 
                  label="let the netizen decide for you" 
                  value={field_check} 
                  setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                />
              </div>
            </div>
            )
          }
          if(field_type === 'int') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.IntField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'float') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.FloatField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'bool' || field_type === 'boolean') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.BoolField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type === 'date') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.DateField 
                    label={field} 
                    sublabel={field_sublabel}
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    margin={false}
                  />
                </div>
                {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if (field_type === 'enum') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.EnumField 
                    label={field} 
                    sublabel={field_sublabel}
                    info={"(select one)"} 
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    values={field_values} 
                    margin={false}
                  />
                </div>
              {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          if(field_type.constructor === Object && field_type.type === 'enum') {
            return (
              <div key={index} className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
                <div key={index} className="w-full flex-1">
                  <Fields.MultiEnumField 
                    label={field} 
                    sublabel={field_sublabel}
                    info={"(select multiple)"} 
                    disabled={field_check_enabled ? field_check === true : false} 
                    value={field_value} 
                    setValue={(value) => updateActivePipelineField(field, value)} 
                    values={field_values} 
                    margin={false}
                  />
                </div>
              {field_check_enabled && <div className="flex flex-col flex-0 justify-center">
                  <Fields.BoolField2 
                    label="let the netizen decide for you" 
                    value={field_check} 
                    setValue={(value) => updateActivePipelineFieldCheck(field, value)} 
                  />
                </div>}
              </div>
            )
          }
          })}
        </div>

        <div className="flex flex-row space-x-4 mb-4">
          <Fields.Button type='wide-styled' label='Create' onClick={()=>onClickCreate(activePipeline)} id="create"/>
        </div>
      </div>
    </NewView>
  );
};

const NewPipelineView = ({
    pipelineTemplate,
    onClickCreate,
    }) => {
  return (
    <NewPipelineViewMain pipelineTemplate={pipelineTemplate} onClickCreate={onClickCreate} />
  );
}

export default NewPipelineView;