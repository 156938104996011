import React, { useState, useRef, useEffect } from 'react';

import RunsView from './RunsView';
import RunDetailView from './RunDetailView';

import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

const Runs = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_JOBS = `${globalConfig.api}/renderjobs`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const [view, setView] = useState('main');

  const [jobs, setJobs] = useState(null);
  const [activeJob, setActiveJob] = useState(null);

  const [message, setMessage] = useState('');

  useEffect(() => {
    loadJobs();
  }, []);

  useEffect(() => {
    switch(view) {
      case 'view':
        setBackEnabled(true);
        break;
      default:
        setBackEnabled(false);
        break;
    }
  }, [view, setBackEnabled]);

  useEffect(() => {
    if(backEvent) {
      switch(view) {
        case 'view':
          setView('main');
          setBackEvent(false);
          break;
        default:
          setView('main');
          setBackEvent(false);
          break;
      }
    }
  }, [view, backEvent, setBackEvent]);

  // load current user's jobs
  const loadJobs = () => {
    axios.get(API_ENDPOINT_JOBS+"?sortBy=_id:desc", {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        console.log('loaded user jobs', response.data.results);
        const resJobs = response.data.results
        setJobs(resJobs);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onSearch = (e) => {
    //alert(`Searching...${e.target.value}`);
  };

  const onClickJob = (job) => {
    console.log('Job clicked', job);
    setActiveJob(job);
    setView('view');
  }

  const onClickPost = (job, info) => {
    console.log('Post clicked', job, info);

    // put info in config and update job to queued
    handleQueueJob(job, info).then(() => {
      setView('main');
    })
    .catch(error => {
      console.error(error);
      setView('main');
    });
  }

  // queue a job with updates
  const handleQueueJob = (job, info) => {
    const config = {
      ...job.config,
      info: info
    }

    return axios.patch(
      `${API_ENDPOINT_JOBS}/${job.id}`, 
      {
        status: 'QUEUED',
        config: config,
      },
      {
        headers: {
          ...authHeader()
        }
      })
      .then(response => {
        // reload jobs
        loadJobs();
      })
  }

  return (
    (view==='main' && <RunsView
      jobs={jobs} 
      title="Runs"
      description="Find all your runs here"
      onSearch={onSearch}
      onClickJob={onClickJob}
    />) ||
    (view==='view' && <RunDetailView 
      job={activeJob}
      onClickPost={onClickPost}
    />)
  );
}

export default Runs;